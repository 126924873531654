/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-live-filtered-table-filter-bar {
  border-bottom: 1px solid #c3c3c3;
}
.mc-color-key {
  line-height: 2rem;
}
.mc-color-key-badge {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  border-radius: 100%;
  border: 1px solid #d9d9d9;
  display: inline-block;
  margin-right: 6px;
  position: relative;
  top: 3px;
}
.mc-color-key-badge-overnight {
  background: #800080;
}
.mc-color-key-badge-expected {
  background: #006E66;
}
.mc-link-btn.mc-live-colored-button,
.ant-btn.mc-link-btn.mc-live-colored-button {
  border: 1px solid transparent;
}
.ant-btn.mc-live-colored-button,
.mc-live-colored-button {
  border-radius: 4px;
  color: #1890ff;
  font-weight: 400;
  white-space: nowrap;
  background-color: transparent;
  background: transparent;
  border-color: transparent;
  text-align: center;
  height: 24px;
  line-height: 24px;
  width: 100%;
  padding: 0 7px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.ant-btn.mc-live-colored-button:hover,
.ant-btn.mc-live-colored-button:focus {
  border: 1px solid #1890ff;
  background: #fff;
}
.ant-btn.mc-live-colored-button:hover .mc-live-colored-button-icon {
  color: rgba(255, 255, 255, 0.9);
}
.ant-btn.mc-live-colored-button > .mc-live-colored-button-children {
  margin-left: 22px;
}
.mc-live-colored-button > .mc-live-colored-button-icon {
  margin-left: 9px;
  color: rgba(255, 255, 255, 0);
}
.mc-live-colored-button > .mc-live-colored-button-children {
  text-align: center;
  flex: 1;
}
.mc-live-colored-button-unclickable.mc-live-colored-button {
  cursor: default !important;
  color: inherit;
}
.mc-live-colored-button-EXPECTED.mc-live-colored-button {
  background-color: #006E66 !important;
  border-color: #006E66 !important;
  color: rgba(255, 255, 255, 0.9) !important;
}
.mc-live-colored-button-EXPECTED.mc-live-colored-button > span {
  color: inherit !important;
}
.mc-live-colored-button-EXPECTED.mc-live-colored-button .mc-live-colored-button:hover {
  color: rgba(255, 255, 255, 0.9) !important;
}
.mc-live-colored-button-EXPECTED.mc-live-colored-button .mc-live-colored-button:hover:not(.mc-live-colored-button-unclickable) {
  background-color: #26847d !important;
  border-color: #26847d !important;
}
.mc-live-colored-button-EXPECTED.mc-live-colored-button .mc-live-colored-button:hover > span {
  color: inherit !important;
}
.mc-live-colored-button-EXPECTED.mc-live-colored-button .mc-live-colored-button.mc-live-colored-button-unclickable:hover,
.mc-live-colored-button-EXPECTED.mc-live-colored-button .mc-live-colored-button.mc-live-colored-button-unclickable:focus {
  background-color: #006E66 !important;
  border-color: #006E66 !important;
}
.mc-live-colored-button-EXPECTED.mc-live-colored-button .mc-live-colored-button:focus {
  color: rgba(255, 255, 255, 0.9) !important;
}
.mc-live-colored-button-EXPECTED.mc-live-colored-button .mc-live-colored-button:focus:not(.mc-live-colored-button-unclickable) {
  background-color: #006E66 !important;
  border-color: #26847d !important;
}
.mc-live-colored-button-EXPECTED.mc-live-colored-button .mc-live-colored-button:focus > span {
  color: inherit !important;
}
.mc-live-colored-button-OVERNIGHT.mc-live-colored-button {
  background-color: #800080 !important;
  border-color: #800080 !important;
  color: rgba(255, 255, 255, 0.9) !important;
}
.mc-live-colored-button-OVERNIGHT.mc-live-colored-button > span {
  color: inherit !important;
}
.mc-live-colored-button-OVERNIGHT.mc-live-colored-button .mc-live-colored-button:hover {
  color: rgba(255, 255, 255, 0.9) !important;
}
.mc-live-colored-button-OVERNIGHT.mc-live-colored-button .mc-live-colored-button:hover:not(.mc-live-colored-button-unclickable) {
  background-color: #932693 !important;
  border-color: #932693 !important;
}
.mc-live-colored-button-OVERNIGHT.mc-live-colored-button .mc-live-colored-button:hover > span {
  color: inherit !important;
}
.mc-live-colored-button-OVERNIGHT.mc-live-colored-button .mc-live-colored-button.mc-live-colored-button-unclickable:hover,
.mc-live-colored-button-OVERNIGHT.mc-live-colored-button .mc-live-colored-button.mc-live-colored-button-unclickable:focus {
  background-color: #800080 !important;
  border-color: #800080 !important;
}
.mc-live-colored-button-OVERNIGHT.mc-live-colored-button .mc-live-colored-button:focus {
  color: rgba(255, 255, 255, 0.9) !important;
}
.mc-live-colored-button-OVERNIGHT.mc-live-colored-button .mc-live-colored-button:focus:not(.mc-live-colored-button-unclickable) {
  background-color: #800080 !important;
  border-color: #932693 !important;
}
.mc-live-colored-button-OVERNIGHT.mc-live-colored-button .mc-live-colored-button:focus > span {
  color: inherit !important;
}
