
.mc-navigation {

  & .ant-menu-submenu > .ant-menu-submenu-title {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    height: 48px;
  }

  &.ant-layout-sider-collapsed .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: 50px;
  }

  &-submenu-manifesting {
    .anticon {
      color: rgb(106, 169, 243);
    }

    .ant-menu-submenu-title {
      background: linear-gradient(90deg, rgba(106, 169, 243, 0.1), transparent)
    }
  }

  &-submenu-flytwatch {
    .anticon {
      color: rgb(241, 243, 106);
    }

    .ant-menu-submenu-title {
      background: linear-gradient(90deg, rgba(241, 243, 106, 0.1), transparent)
    }
  }

  &-submenu-report {
    .anticon {
      color: rgb(243, 172, 106);
    }

    .ant-menu-submenu-title {
      background: linear-gradient(90deg, rgba(243, 172, 106, 0.1), transparent)
    }
  }

  &-submenu-masterdata {
    .anticon {
      color: rgb(122, 243, 106);
    }

    .ant-menu-submenu-title {
      background: linear-gradient(90deg, rgba(122, 243, 106, 0.1), transparent)
    }
  }

  &-submenu-management {
    .anticon {
      color: rgb(200, 106, 243);
    }

    .ant-menu-submenu-title {
      background: linear-gradient(90deg, rgba(200, 106, 243, 0.1), transparent)
    }
  }

  &-submenu-compliance {
    .anticon {
      color: rgb(243, 106, 106);
    }

    .ant-menu-submenu-title {
      background: linear-gradient(90deg, rgba(243, 106, 106, 0.1), transparent)
    }
  }
}