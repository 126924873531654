.bp3-editable-text-input {

    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: none;
    width: 100%;
    padding: 0;
    white-space: pre-wrap;

}
.bp3-editable-text-input, .bp3-editable-text-content {

    display: inherit;
    outline: none;
    position: relative;
    min-width: inherit;
    max-width: inherit;
    vertical-align: top;
    text-transform: inherit;
    letter-spacing: inherit;
    color: inherit;
    font: inherit;
        line-height: inherit;
    resize: none;

}

.bp3-editable-text {

    cursor: text;
    white-space: nowrap;

}

.bp3-editable-text-editing > .bp3-editable-text-content {

    position: absolute;
    left: 0;
    visibility: hidden;

}
.bp3-editable-text-content {

    overflow: hidden;
    padding-right: 2px;
    text-overflow: ellipsis;
    white-space: pre;

}

.bp3-editable-text-input, .bp3-editable-text-content {

    display: inherit;
    position: relative;
    min-width: inherit;
    max-width: inherit;
    vertical-align: top;
    text-transform: inherit;
    letter-spacing: inherit;
    color: inherit;
    font: inherit;
        line-height: inherit;
    resize: none;

}