
.mc-phase-1-history {
  &-daterange {
    transform: translateY(-1px);

    & > .ant-input {
      border-right: none;
      border-left: none;
      border-radius: 0;
      min-width: 180px;
    }
  }
}