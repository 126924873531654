/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-fw-fuel-taken-title {
  margin: 0;
}
.mc-fw-fuel-taken-header {
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 0 12px;
  display: flex;
  align-items: center;
}
