/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.cell-isn-status-selectable:hover {
  box-shadow: inset 0 0 0 2px rgba(69, 135, 255, 0.73);
  cursor: pointer;
}
.cell-isn-status.cell-isn-status-red {
  color: #f8646c;
  background-color: rgba(245, 34, 45, 0.5);
}
.cell-isn-status.cell-isn-status-green {
  color: #86d65f;
  background-color: rgba(82, 196, 26, 0.5);
}
.cell-isn-status.cell-isn-status-multi {
  color: #fcc65b;
  background-color: rgba(250, 173, 20, 0.5);
}
.cell-isn-status.cell-isn-status-override {
  color: #5db1ff;
  background-color: rgba(24, 144, 255, 0.5);
}
.cell-isn-status {
  display: flex;
  justify-content: center;
}
