/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.notes-for-pilot-quickedit-wrapper {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.notes-for-pilot-quickedit-textarea[disabled] {
  cursor: text !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
.notes-for-pilot-quickedit-body {
  padding: 12px;
}
.notes-for-pilot-quickedit-preview[disabled],
.notes-for-pilot-quickedit-preview-disabled {
  border: none;
  background-color: transparent !important;
  cursor: default;
}
.notes-for-pilot-quickedit-preview.ant-btn {
  background-color: #fff;
  display: block;
  height: 24px;
  padding: 1px 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  font-size: 0.7rem;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.65);
}
.notes-for-pilot-quickedit-preview.ant-btn:not([disabled]) {
  cursor: text;
}
.notes-for-pilot-quickedit-preview.ant-btn > span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 100%;
}
.notes-for-pilot-quickedit-preview.ant-btn:hover {
  color: rgba(0, 0, 0, 0.65);
}
