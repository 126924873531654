/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.editable-pref-table .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}
.editable-pref-table .editable-row .editable-cell-value-wrap {
  padding: 2px 12px;
  cursor: pointer;
}
.editable-pref-table .editable-row input {
  padding: 2px 12px;
  height: auto;
}
.editable-pref-table .editable-row .ant-form-item-control {
  line-height: inherit;
}
.editable-pref-table .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 1px 11px;
  min-height: 21px;
}
.editable-pref-table .ant-table-tbody > tr.editable-row:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: rgba(64, 169, 255, 0.1);
}
.editable-pref-table-attr-key {
  margin-left: 8px;
  text-transform: uppercase;
}
