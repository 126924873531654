/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.dep-dest-select-wrapper {
  width: 100%;
  display: flex;
}
.dep-dest-select-wrapper > *:not(:last-child) {
  margin-right: -1px;
}
.dep-dest-select-wrapper > .ant-select {
  flex: 1;
}
.dep-dest-select-wrapper > .ant-select:hover {
  z-index: 1;
}
.dep-dest-select-wrapper > .ant-select > .ant-select-selection,
.dep-dest-select-wrapper > span > .ant-btn {
  border-radius: 0;
}
.dep-dest-select-wrapper > .ant-select:first-child > .ant-select-selection,
.dep-dest-select-wrapper > span:first-child > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.dep-dest-select-wrapper > .ant-select:last-child > .ant-select-selection,
.dep-dest-select-wrapper > span:last-child > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
