/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.paxcgo-scheduler-content {
  display: flex;
  flex-direction: column;
}
.paxcgo-scheduler-layout .ant-tabs {
  background-color: #fff;
}
.paxcgo-scheduler-toolbar {
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 6px 12px;
}
.paxcgo-scheduler-table-header {
  background-color: #cccccc;
  margin: 12px;
  margin-bottom: 0;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}
.paxcgo-scheduler-table-header > .ant-divider-vertical {
  height: 100%;
}
.paxcgo-scheduler-table-header {
  padding: 8px;
}
.paxcgo-scheduler-table thead th {
  border-radius: 0 !important;
  background-color: #fafafa !important;
}
.paxcgo-scheduler-table td > .paxcgo-scheduler-table-on-flight {
  border-radius: 8px;
  background: linear-gradient(to right, #237804 10%, transparent);
  padding: 0.25rem;
  display: block;
}
.paxcgo-scheduler-table td > .paxcgo-scheduler-table-on-flight,
.paxcgo-scheduler-table td > .paxcgo-scheduler-table-on-flight > .ant-btn,
.paxcgo-scheduler-table td > .paxcgo-scheduler-table-on-flight > a {
  color: #fff;
}
.paxcgo-scheduler-table td > .paxcgo-scheduler-table-on-flight > .ant-btn:hover > span {
  text-decoration: underline;
}
.paxcgo-scheduler-search-layout {
  background: #fff;
}
.paxcgo-scheduler-cargo-footer {
  border-top: 1px solid #d9d9d9;
  padding: 12px;
  background: #fff;
}
.paxcgo-scheduler-collapse.ant-collapse {
  border: none;
  background-color: transparent;
}
.paxcgo-scheduler-collapse.ant-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  background-color: #fafafa;
}
.paxcgo-scheduler-collapse .ant-collapse-item {
  border: 1px solid #d9d9d9;
  border-radius: 4px !important;
  overflow: hidden;
}
.paxcgo-scheduler-collapse .ant-collapse-item:not(:last-child) {
  margin-bottom: 12px;
}
.paxcgo-scheduler-collapse .ant-table-small {
  border: none;
}
.paxcgo-scheduler-collapse .ant-table-small .ant-table-body {
  margin: 0;
}
.paxcgo-scheduler-collapse .ant-collapse-content-box {
  padding: 0;
}
