/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.scheduled-paxcgo-adder-add-button.ant-btn {
  display: none;
}
.scheduled-paxcgo-adder-collapse-panel > .ant-collapse-header:hover .scheduled-paxcgo-adder-add-button {
  display: inline-block;
}
.scheduled-paxcgo-adder-collapse-panel > .ant-collapse-header {
  padding-left: 75px !important;
}
.scheduled-paxcgo-adder-collapse-panel > .ant-collapse-header > .ant-collapse-arrow {
  left: 51px !important;
}
.scheduled-paxcgo-adder-collapse-panel > .ant-collapse-header > .ant-collapse-extra {
  flex-shrink: 0;
  min-width: fit-content;
  float: initial !important;
}
.scheduled-paxcgo-adder-collapse-panel-checkbox-hitbox {
  position: absolute;
  left: 0;
  height: 100%;
  width: 52px;
  cursor: pointer;
}
.scheduled-paxcgo-adder-collapse-panel-checkbox {
  position: absolute;
  left: 21.5px;
}
.scheduled-paxcgo-adder-table .scheduled-paxcgo-adder-table-row-selectable {
  cursor: pointer;
}
.scheduled-paxcgo-adder-table:not(:first-child) {
  border-top: 1px solid #d9d9d9;
}
.scheduled-paxcgo-adder-table thead th {
  border-radius: 0 !important;
  background-color: #ffffff08 !important;
}
.scheduled-paxcgo-adder-table .ant-table-small {
  border: none;
}
.scheduled-paxcgo-adder-table .ant-table-small .ant-table-body {
  margin: 0;
}
.scheduled-paxcgo-adder-group-collapse > .ant-collapse-item > .ant-collapse-header,
.scheduled-paxcgo-adder-legs-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 6px !important;
  display: flex;
  align-items: center;
}
.scheduled-paxcgo-adder-group-collapse > .ant-collapse-item > .ant-collapse-header > .ant-collapse-extra,
.scheduled-paxcgo-adder-legs-collapse > .ant-collapse-item > .ant-collapse-header > .ant-collapse-extra {
  margin-left: auto;
}
.scheduled-paxcgo-adder-group-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 6px;
}
.scheduled-paxcgo-adder-sections-wrapper {
  display: flex;
  position: relative;
  flex: 1;
  align-items: center;
  min-width: 0;
  overflow: hidden;
}
.scheduled-paxcgo-adder-sections-wrapper::after {
  content: "";
  position: absolute;
  width: 60px;
  right: 0;
  height: 100%;
  background: linear-gradient(to right, transparent, #fafafa);
}
.scheduled-paxcgo-adder-legs-collapse > .ant-collapse-item {
  margin-bottom: 6px !important;
}
.scheduled-paxcgo-adder-legs-collapse > .ant-collapse-item:last-of-type {
  margin-bottom: 0 !important;
}
.scheduled-paxcgo-adder-legs-collapse .ant-collapse-header {
  background-color: #fff;
}
.scheduled-paxcgo-adder .scheduled-paxcgo-adder-section {
  border-right: 1px solid #d9d9d9;
  padding: 8px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scheduled-paxcgo-adder .scheduled-paxcgo-adder-section:first-of-type {
  border-left: 1px solid #d9d9d9;
  margin-left: 6px;
}
.scheduled-paxcgo-adder .scheduled-paxcgo-adder-section:last-of-type {
  border-right-width: 0;
}
