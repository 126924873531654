/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-layout.ant-layout-flex-row {
  flex-direction: row;
}
.mc-masterdata-body {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 0;
}
.mc-masterdata-table-wrapper {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
}
.mc-masterdata-details-wrapper {
  display: flex;
  overflow: auto;
  border-left: 1px solid #d9d9d9;
  min-width: 25rem;
}
.mc-masterdata-layout {
  height: 100%;
}
@media screen and (max-width: 850px) {
  .mc-masterdata-details-content {
    width: auto !important;
  }
  .mc-masterdata-details-wrapper {
    border-left: none !important;
    min-width: 0;
    width: 100%;
  }
  .mc-masterdata-body.mc-masterdata-body-details-open {
    display: none;
  }
}
