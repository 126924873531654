/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
div.nonidealstate-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
i.nonidealstate-icon,
.svg-inline--fa.nonidealstate-icon {
  font-size: 4rem;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 1rem;
}
i.nonidealstate-icon-primary,
.svg-inline--fa.nonidealstate-icon-primary {
  color: #1890ff;
}
.nonidealstate-icon {
  transform: scale(0);
  animation: 0.4s nonidealstate-grow ease-out forwards;
  animation-iteration-count: 1;
}
.nonidealstate-title {
  transform: scale(0);
  animation: 0.4s nonidealstate-grow ease-out forwards;
  animation-delay: 0.1s;
  animation-iteration-count: 1;
}
.nonidealstate-description {
  transform: scale(0);
  animation: 0.4s nonidealstate-grow ease-out forwards;
  animation-delay: 0.2s;
  animation-iteration-count: 1;
}
@keyframes nonidealstate-grow {
  0% {
    transform: scale(0.7);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
