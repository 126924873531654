
.mc-icon-smile .mc-icon-smile-eye {
    transform: scale(1, 1);
}

@keyframes smile-blink {
    0% {
        .mc-icon-smile .mc-icon-smile-eye {
            transform: scale(1, 1);
        }
    }
    50% {
        .mc-icon-smile .mc-icon-smile-eye {
            transform: scale(1, 0);
        }
    }
    100% {
        .mc-icon-smile .mc-icon-smile-eye {
            transform: scale(1, 1);
        }
    }
}