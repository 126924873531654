
.mc-iofu {
    width: 100%;
    & .ant-upload-list-item {
        float: left;
        width: 200px;
        margin-right: 8px;
    }
    & .ant-upload-animate-enter {
        animation-name: uploadAnimateInlineIn;
    }
    & .ant-upload-animate-leave {
        animation-name: uploadAnimateInlineOut;
    }

    &.mc-iofu-readonly .ant-upload-select {
        display: none;
    }

    &.mc-iofu-addonly .ant-upload-list-item-card-actions > a[title="Remove file"] {
        display: none;
    }
}