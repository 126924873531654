.login-page-wrapper {
    background-color: #204784;
    height: 100vh;
    overflow: auto;
}

.container {
    max-width: 37rem;
    margin: 0rem auto;
}

.login-box {
    border: none;
    border-radius: 1rem;
    box-shadow: 1rem 1rem 0 #CFD8DC;
    background-color: #f3f5f6;
    animation: loginBoxFadeIn 500ms;
    padding: 2rem;
}

.login-box.card .card-body {
    padding: 3rem;
}

.mc-logo {
    width: 100%;
    padding: 0 5rem;
}

@keyframes loginBoxFadeIn {
    0% {
        opacity: 0;
        transform: scale(0.8);
        box-shadow: 0 0 0 #CFD8DC;
    }
    100% {
        opacity: 1;
        transform: scale(1);
        box-shadow: 1rem 1rem 0 #CFD8DC;
    }
}