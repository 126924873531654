/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-flightboard {
  height: 100vh;
}
.mc-flightboard .mc-flightboard-controls {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 4px 8px;
}
.mc-flightboard .mc-flightboard-company-logo-wrapper {
  display: inline-block;
  margin-right: 1rem;
  line-height: 50px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
}
.mc-flightboard .mc-flightboard-company-logo {
  height: 50px;
  display: inline-block;
  border-radius: 4px;
}
.mc-flightboard .mc-flightboard-df-logo {
  height: 50px;
  display: inline-block;
  margin-left: auto;
}
.mc-flightboard .mc-flightboard-company-logo-alt {
  margin: 0 12px;
  font-size: 1.1rem;
  text-transform: uppercase;
}
.mc-flightboard .mc-flightboard-title {
  text-transform: capitalize;
}
.mc-flightboard .mc-flightboard-header,
.mc-flightboard .mc-flightboard-footer {
  background: #0050b3 !important;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  position: relative;
}
.mc-flightboard .mc-flightboard-header .ant-typography,
.mc-flightboard .mc-flightboard-footer .ant-typography {
  color: #ffffff;
}
.mc-flightboard .mc-flightboard-footer {
  border-top: 2px solid #0050b3;
}
.mc-flightboard .mc-flightboard-header {
  border-bottom: none;
}
.mc-flightboard .mc-flightboard-footer .ant-typography {
  font-size: 1.4rem;
}
.mc-flightboard .mc-flightboard-table-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  width: 100%;
}
.mc-flightboard .mc-table thead th {
  border-bottom: 2px solid #0050b3 !important;
  border-top: 2px solid #0050b3;
  color: #ffffff !important;
  background-color: #003d89 !important;
  font-weight: 200;
}
.mc-flightboard .mc-table th {
  border-bottom: 2px solid #0050b3;
  border-top: 2px solid #0050b3;
  color: #ffffff;
  background-color: #003d89;
  font-weight: 200;
}
.mc-flightboard .mc-table td {
  font-weight: 500;
}
.mc-flightboard .mc-table .ant-table-tbody > tr:hover > td {
  background: none !important;
}
.mc-flightboard .mc-table td,
.mc-flightboard .mc-table th {
  font-size: 1.2rem;
  border-radius: 0 !important;
}
.mc-flightboard .mc-table tr > td:first-child,
.mc-flightboard .mc-table tr > th:first-child {
  padding-left: 1rem !important;
}
.mc-flightboard .mc-table tr > td:last-child,
.mc-flightboard .mc-table tr > th:last-child {
  padding-right: 1rem !important;
}
.mc-flightboard .mc-flightboard-footer .mc-flightboard-data-refresh-indicator {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.8rem;
}
.mc-flightboard .mc-flightboard-group-letter {
  font-size: 1.5rem !important;
}
.mc-flightboard .mc-table th.mc-flightboard-group-letter {
  background-color: #3364a1 !important;
  color: #ffff00 !important;
  font-weight: 500;
}
.mc-flightboard .mc-flightboard-depart-legend {
  display: flex;
  gap: 6px;
  align-items: center;
}
.mc-flightboard .mc-flightboard-as-on,
.mc-flightboard .mc-flightboard-as-off {
  margin-left: 3px;
  color: #ffff00;
}
.mc-flightboard .mc-flightboard-as-btn {
  color: white;
}
.mc-flightboard .mc-table .ant-table-row.mc-flightboard-est-in-30-minutes,
.mc-flightboard .mc-flightboard-est-in-30-minutes {
  color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  background-color: #09ff00;
  padding: 6px;
  font-weight: 500;
}
.mc-flightboard .mc-table .ant-table-row.mc-flightboard-est-in-1-hour,
.mc-flightboard .mc-flightboard-est-in-1-hour {
  color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  background-color: #ffd900;
  padding: 6px;
  font-weight: 500;
}
