@import '../../../theme/layout.less';

.mc-live-flights {
    .mc-live-header-contain-left {
        min-width: 0;
        position: relative;
    }
    .mc-live-header-contain-right {
        flex-grow: 0;
        white-space: nowrap;
    }
}

@media screen and (max-width: @screen-size-medium) {
    .mc-live-flights .mc-live-header-contain-left > .mc-live-header-title-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        align-items: flex-start;
        overflow: hidden;
        text-overflow: ellipsis;
        
        > * {
            text-overflow: ellipsis;
        }
    }
}