/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-flight-state {
  display: flex;
  align-items: center;
}
.mc-flight-state-lg {
  font-size: 17px;
}
.mc-flight-state-icon {
  font-size: 1.5rem;
  margin-right: 0.6rem;
}
.mc-flight-state-icon-lg {
  font-size: 1.6rem;
}
.mc-flight-state-icon-OVERNIGHT {
  color: #BF97E1;
}
.mc-flight-state-green {
  color: #52c41a;
}
.mc-flight-state-red {
  color: #f5222d;
}
.mc-flight-state-blue {
  color: #1890ff;
}
.mc-flight-state-yellow {
  color: #faad14;
}
.mc-flight-state-purple {
  color: #722ed1;
}
