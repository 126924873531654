/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-masterdata-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
}
.mc-masterdata-details > .mc-masterdata-details-header {
  padding: 0.5rem;
  border-bottom: 1px solid #d9d9d9;
}
.mc-masterdata-details-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 500;
  font-size: 16px;
}
.mc-masterdata-details .mc-masterdata-details-content {
  flex: 1;
  overflow: auto;
  width: 30rem;
  padding: 0.5rem;
}
.mc-masterdata-details .mc-duplication-checker {
  margin-bottom: 1rem;
}
@media screen and (max-width: 560px) {
  .mc-masterdata-details-cancel-save-col {
    width: 100%;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 1px solid #d9d9d9;
  }
  .mc-masterdata-details-cancel-save-row {
    justify-content: space-between;
  }
}
