/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-quartermaster {
  /*
  dashboard .header .title, dashboard .header .mc-qm-icon {
    color: #0D47A1;
  }
  */
  /*
    .mc-qm-sider .list-item.active {
      background-color: transparent;
    }
  */
  /*
    .mc-qm-sider .list-item.active .title, .mc-qm-sider .list-item.active .mc-qm-icon{
      color: #64B5F6;
      background-color: transparent;
    }
  */
  /*------------ OUR CONTEXT MAIN QM HEADER-----------------*/
  /*------------ BOX TURNED AT 45 DEGREE ANGLE (ROW SELECT TICK)-----------------*/
  /*
    .mc-qm-sider .list-item:hover {
      background-color: transparent;
    }
  
    .mc-qm-sider .list-item:hover .title, .mc-qm-sider .list-item:hover .mc-qm-icon{
      color: #64B5F6;
      background-color: transparent;
    }
  */
  /*
  .list-group.padded .list-item {
    border-radius: 0.4rem;
  }
  */
  /*
  table {
    border-collapse: collapse;
      width: 100%;
  }
  thead {
    border-bottom: 2px solid #ccc;
    padding: 1rem;
    color: #0D47A1;
  }
  td, th {
      border: none;
      text-align: left;
      padding: 8px;
  }
  */
  /*
  
  tr {
    border-bottom: 1px solid #ccc;
  }
  */
}
.mc-quartermaster .profile-avatar-thumbnail {
  background-color: white;
  border-radius: 3rem;
  min-width: 2rem;
  max-width: 2rem;
  min-height: 2rem;
  max-height: 2rem;
  margin-left: 1rem;
  margin-right: 0.5rem;
  overflow: hidden;
}
.mc-quartermaster .profile-avatar {
  height: 6rem;
  width: 6rem;
  border-radius: 10rem;
  overflow: hidden;
}
.mc-quartermaster .profile-avatar img {
  width: 100%;
}
.mc-quartermaster .profile-details {
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
}
.mc-quartermaster .profile-name {
  font-size: 130%;
}
.mc-quartermaster .profile-content > .card-header .nav-link.active {
  background-color: #2196F3;
  color: white;
}
.mc-quartermaster .profile-content > .card-header .nav-link {
  color: inherit;
}
.mc-quartermaster .profile-content > .card-header .nav-link:not(.active):hover {
  color: #2196F3;
}
.mc-quartermaster hl {
  border-bottom: 1px solid #bdbdbd;
  margin: 0.8rem auto 0.8rem auto;
  width: 80%;
}
.mc-quartermaster .logo {
  font-size: 120%;
  margin: 1rem;
}
.mc-quartermaster .badge {
  position: absolute;
  background-color: #0D47A1;
  border-radius: 3rem;
  font-size: 75%;
  color: white;
  top: 0.5rem;
  right: 0;
  padding: 0.1rem 0.4rem;
}
.mc-quartermaster .badge.critical {
  background-color: #F44336;
}
.mc-quartermaster .badge.normal {
  background-color: #0D47A1;
}
.mc-quartermaster .badge.warning {
  background-color: #FFC107;
  color: #333;
}
.mc-quartermaster .button {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 250ms;
  cursor: pointer;
  user-select: none;
  padding: 0.5rem;
}
.mc-quartermaster .button:hover {
  background-color: #eee;
  transition: none;
}
.mc-quartermaster .box {
  display: flex;
  flex-direction: column;
  min-height: 10rem;
  background-color: white;
  border-radius: 0.2rem;
  border: 1px solid #ccc;
  margin: 0.5rem;
  padding: 0;
  overflow: hidden;
  flex: 1;
}
.mc-quartermaster .card {
  margin: 0.5rem;
  overflow: hidden;
}
.mc-quartermaster .card.bordered .head {
  border-bottom: solid 1px #ccc;
  color: inherit;
  background-color: white;
}
.mc-quartermaster .card .card-block {
  overflow-y: auto;
  padding: 0;
}
.mc-quartermaster .grid-container .card-header {
  text-transform: capitalize;
  padding: 0.6rem 1rem;
  font-weight: bold;
  background-color: white;
  color: #2196F3;
}
.mc-quartermaster .card .head .title {
  font-weight: bold;
}
.mc-quartermaster .card.pob {
  grid-column: 0.5;
  grid-row: 0.25;
}
.mc-quartermaster .card.bed {
  grid-column: 0.66666667;
  grid-row: 0.75;
}
.mc-quartermaster .card.arrive {
  grid-column: 0.66666667;
  grid-row: 0.5;
}
.mc-quartermaster .card.depart {
  grid-column: 0.66666667;
  grid-row: 0.66666667;
}
.mc-quartermaster .card.profile {
  grid-column: span 2;
  padding: 1.5rem;
  flex-direction: row;
  align-items: center;
  border-left: 0.3rem solid #2196F3;
}
.mc-quartermaster .card.profile span {
  text-align: center;
}
.mc-quartermaster .profile-content .card-group {
  height: 100%;
}
.mc-quartermaster .profile-content .card-group .card {
  margin: 0;
  border-radius: 0;
  border-left: none;
  border-top: none;
  border-bottom: none;
}
.mc-quartermaster .tab-bar {
  display: flex;
  background-color: #0d47a1;
  padding: 0.5rem;
}
.mc-quartermaster .tab {
  display: flex;
  padding: 0.8rem;
  cursor: pointer;
  border-radius: 0.2rem;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.7);
}
.mc-quartermaster .tab.active {
  background-color: white;
  color: #0d47a1;
}
.mc-quartermaster .tab.active:hover {
  color: #0d47a1;
}
.mc-quartermaster .tab:hover {
  color: white;
}
.mc-quartermaster .grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 0.5rem;
  padding: 0.5rem;
}
.mc-quartermaster .grid-container-profile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 0.5rem;
  padding: 0.5rem;
}
.mc-quartermaster .grid-container-profile .tab-bar {
  grid-column: 0.33333333;
  grid-row: 0.66666667;
}
.mc-quartermaster .grid-container .card,
.mc-quartermaster .grid-container-profile .card {
  margin: 0;
}
.mc-quartermaster .profile-content {
  flex: 1;
}
.mc-quartermaster .profile-content .body {
  flex-direction: row;
}
.mc-quartermaster .profile-content .body .card {
  flex: 1;
}
.mc-quartermaster .destinfo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3rem;
  width: 0.5rem;
  padding: 0 1rem;
}
.mc-quartermaster .destinfo.critical {
  background-color: #F44336;
  color: white;
}
.mc-quartermaster .destinfo.warning {
  background-color: #FFC107;
  color: #37474f;
}
.mc-quartermaster .destinfo.none {
  background-color: #bdbdbd;
  color: #37474f;
}
.mc-quartermaster .disabled {
  color: #BDBDBD;
  pointer-events: none;
}
.mc-quartermaster .hamburger-menu {
  height: 4rem;
  width: 4rem;
  min-height: 4rem;
  min-width: 4rem;
}
.mc-quartermaster body > :not(.mc-qm-sider) .hamburger-menu {
  display: flex;
}
.mc-quartermaster .mc-qm-sider .hamburger-menu {
  display: none;
}
.mc-quartermaster .header,
.mc-quartermaster .footer {
  display: flex;
  height: 4rem;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  background-color: white;
  padding: 0;
  white-space: nowrap;
}
.mc-quartermaster .footer {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.mc-quartermaster .header .filter-input,
.mc-quartermaster .footer .filter-input {
  margin-left: auto;
  margin-right: 0.5rem;
}
.mc-quartermaster .header .search-input,
.mc-quartermaster .footer .search-input {
  width: 100%;
}
.mc-quartermaster .header .title,
.mc-quartermaster .footer .title {
  margin: 0 0.5rem;
  font-size: 130%;
}
.mc-quartermaster .footer .title {
  font-size: 100%;
}
.mc-quartermaster .header input,
.mc-quartermaster .footer input {
  align-self: stretch;
  margin: 0.5rem;
}
.mc-quartermaster .header.transparent,
.mc-quartermaster .footer.transparent {
  background-color: transparent;
  border: none;
}
.mc-quartermaster .header.colored {
  background-color: #37474f;
  color: white;
  border: none;
}
.mc-quartermaster .header.colored .button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.mc-quartermaster .header.colored input {
  border: none;
}
.mc-quartermaster .header.vertical {
  height: auto;
  flex-direction: column;
}
.mc-quartermaster .toolbar input {
  border: none;
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-radius: 0;
  margin: 0;
}
.mc-quartermaster .toolbar input:first-of-type {
  border-left: none;
}
.mc-quartermaster .tooltip {
  position: relative;
}
.mc-quartermaster .tooltip .tooltiptext {
  visibility: hidden;
  width: 8rem;
  background-color: #0D47A1;
  color: #fff;
  text-align: center;
  border-radius: 4px 4px 0 4px;
  position: absolute;
  z-index: 1;
  bottom: 170%;
  right: 1.4rem;
  margin-left: -60px;
  pointer-events: none;
  padding: 0.3rem;
  display: flex;
  justify-content: center;
}
.mc-quartermaster .tooltip:hover .tooltiptext {
  visibility: visible;
}
.mc-quartermaster .tooltiptext:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  bottom: -0.6rem;
  position: absolute;
  right: 0;
  border: 0.3rem solid;
  border-color: #0D47A1 #0D47A1 transparent transparent;
}
.mc-quartermaster content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}
.mc-quartermaster dashboard {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.mc-quartermaster .mc-qm-card {
  display: flex;
  flex-direction: column;
}
.mc-quartermaster .mc-qm-card .ant-card-body {
  overflow: auto;
}
.mc-quartermaster .mc-qm-card .ant-card-head-title,
.mc-quartermaster .mc-qm-card .ant-card-extra {
  font-weight: bold;
}
.mc-quartermaster .mc-qm-card .ant-card-head-title .svg-inline--fa,
.mc-quartermaster .mc-qm-card .ant-card-extra .svg-inline--fa {
  margin-right: 0.5rem;
}
.mc-quartermaster .mc-qm-card.mc-qm-card-onboard .ant-card-head-title,
.mc-quartermaster .mc-qm-card.mc-qm-card-onboard .ant-card-extra {
  color: #50b337;
}
.mc-quartermaster .mc-qm-card.mc-qm-card-expected .ant-card-head-title,
.mc-quartermaster .mc-qm-card.mc-qm-card-expected .ant-card-extra {
  color: #da5401;
}
.mc-quartermaster .mc-qm-card.mc-qm-card-departing .ant-card-head-title,
.mc-quartermaster .mc-qm-card.mc-qm-card-departing .ant-card-extra {
  color: #b649ff;
}
.mc-quartermaster dashboard .header .mc-qm-icon {
  margin-right: 0.3rem;
}
.mc-quartermaster .list-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.5rem 1rem;
  transition: background-color 0ms;
  white-space: nowrap;
  position: relative;
}
.mc-quartermaster .list-item .list-detail {
  display: flex;
  flex-direction: column;
}
.mc-quartermaster .list-detail.pull-right {
  margin-left: auto;
  text-align: right;
  font-size: 75%;
}
.mc-quartermaster .list-item .photo {
  border-radius: 10rem;
  background-color: black;
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
}
.mc-quartermaster .list-item .list-detail .subtitle {
  color: gray;
}
.mc-quartermaster .list-item.active {
  background-color: rgba(33, 150, 243, 0.35);
  transition: box-shadow 250ms;
  box-shadow: inset 0.2rem 0 0 #64B5F6;
}
.mc-quartermaster .list-item .title {
  text-transform: uppercase;
}
.mc-quartermaster .mc-qm-content-header {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid #d9d9d9;
}
.mc-quartermaster .mc-qm-content-header .title {
  font-weight: bold;
  font-size: 1.05rem;
}
.mc-quartermaster .mc-qm-sider .list-item:after {
  content: "";
  width: 1rem;
  background-color: transparent;
  height: 1rem;
  position: absolute;
  right: -2rem;
  transform: rotate(45deg);
  transition: right 250ms;
  opacity: 0;
}
.mc-quartermaster .mc-qm-sider .list-item.active:after {
  content: "";
  width: 1rem;
  background-color: #f0f2f5;
  height: 1rem;
  position: absolute;
  right: -0.7rem;
  transform: rotate(45deg);
  opacity: 1;
}
.mc-quartermaster .mc-qm-sider .list-item .title {
  margin-right: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mc-quartermaster .list-item:hover {
  background-color: rgba(33, 150, 243, 0.35);
}
.mc-quartermaster .destinfowrap {
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
}
.mc-quartermaster .destinfowrap span {
  display: flex!important;
  align-items: center;
  justify-content: center;
  width: 1rem;
  /* margin: -.4rem 0; */
  padding: 0 1.2rem;
  margin-right: 0.5rem;
  position: relative;
  transform: translateY(-0.12rem);
}
.mc-quartermaster .destinfowrap span.mc-qm-loc-list-header:after {
  content: "";
  position: absolute;
  background-color: yellow;
  width: 16px;
  height: 2px;
  bottom: -0.38rem;
}
.mc-quartermaster .destinfowrap span.mc-qm-loc-list-header-onboard:after {
  background-color: #49f532;
}
.mc-quartermaster .destinfowrap span.mc-qm-loc-list-header-expected:after {
  background-color: #da5401;
}
.mc-quartermaster .destinfowrap span.mc-qm-loc-list-header-departing:after {
  background-color: #b649ff;
}
.mc-quartermaster .destinfowrap span.mc-qm-loc-list-header-bed:after {
  background-color: #7fdbf7;
}
.mc-quartermaster .mc-qm-sider {
  margin: 0;
  padding: 0;
  overflow: auto;
  overflow-x: hidden;
}
.mc-quartermaster .mc-qm-sider .dashboard:last-child .list-group.bordered {
  border-top: 1px solid rgba(255, 255, 255, 0.125);
}
.mc-quartermaster .mc-qm-sider .dashboard:first-child .list-group.bordered {
  border-top: none;
}
.mc-quartermaster .list-group.padded {
  padding: 0.5rem;
}
.mc-quartermaster .list-header {
  display: flex;
  align-items: center;
  padding: 0.6rem 1rem;
}
.mc-quartermaster .list-header .info,
.mc-quartermaster .card .info {
  float: right;
}
.mc-quartermaster .list-header .title,
.mc-quartermaster .card .span {
  font-weight: bold;
  text-transform: uppercase;
}
.mc-quartermaster .list-header.expand {
  color: #0D47A1;
  text-transform: uppercase;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 -0.5rem;
}
.mc-quartermaster input {
  border-radius: 0.3rem;
  border: none;
  border: 2px solid #eee;
  background-color: #eee;
  font-size: inherit;
  padding: 1rem;
  transition: border 250ms;
  outline: none;
}
.mc-quartermaster input:focus {
  border: 2px solid #2196F3;
}
.mc-quartermaster .mc-qm-sider input {
  background-color: #546E7A;
  border: 2px solid transparent;
  color: rgba(255, 255, 255, 0.8);
}
.mc-quartermaster .mc-qm-sider input:focus {
  border: 2px solid rgba(255, 255, 255, 0.8);
}
.mc-quartermaster .mc-qm-sider input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.mc-quartermaster .mc-qm-sider {
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  min-width: 20rem;
  flex-grow: 0;
  flex-shrink: 1;
  background-color: #37474f;
  transition: transform 250ms ease-in-out, box-shadow 250ms ease-in-out;
  color: white;
}
.mc-quartermaster .mc-qm-sider .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
.mc-quartermaster .mc-qm-icon {
  margin-right: 0.5rem;
  width: 1rem;
}
.mc-quartermaster .breadcrumb {
  background-color: transparent !important;
}
.mc-quartermaster .header .breadcrumb {
  margin: 0;
}
.mc-quartermaster .breadcrumb a {
  color: #2196F3 !important;
}
.mc-quartermaster .breadcrumb a:hover {
  text-decoration: underline !important;
}
.mc-quartermaster .mc-qm-icon {
  margin-right: 1rem;
  width: 1rem;
}
.mc-quartermaster dashboard .mc-qm-icon {
  width: auto;
}
.mc-quartermaster .mc-qm-sider content {
  display: block;
}
.mc-quartermaster span.brand {
  color: #757575;
}
.mc-quartermaster navbar {
  display: flex;
  flex-direction: column;
  background-color: #263238;
  color: white;
  width: 15rem;
  transition: width 250ms, transform 250ms;
  overflow: hidden;
}
.mc-quartermaster navbar .button:hover,
.mc-quartermaster .mc-qm-sider .button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.mc-quartermaster navbar.closed {
  width: 4rem;
}
.mc-quartermaster navbar span {
  opacity: 1;
  transition: opacity 250ms;
}
.mc-quartermaster navbar.closed span {
  opacity: 0;
}
.mc-quartermaster tr:nth-child(even) {
  background-color: #dddddd;
}
.mc-quartermaster a {
  cursor: pointer;
  color: #0d47a1;
}
.mc-quartermaster a:hover {
  text-decoration: underline;
}
.mc-quartermaster .breadcrumb-seperator {
  margin: 0 0.3rem;
}
@media (max-width: 1200px) {
  .mc-quartermaster .mc-qm-sider .hamburger-menu {
    display: flex;
  }
  .mc-quartermaster navbar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    transform: translateX(0);
    box-shadow: 3px 0 40px rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  .mc-quartermaster navbar.closed {
    transform: translateX(-100%);
    width: auto;
    box-shadow: none;
    border: none;
    width: 15rem;
  }
}
@media (max-width: 350px) {
  .mc-quartermaster navbar {
    width: 100%;
  }
}
.mc-quartermaster .mc-qm-sider {
  background-color: #37474f;
}
