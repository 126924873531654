/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-flytwatch-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.mc-flytwatch-content {
  margin: -12px;
  margin-top: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.mc-flytwatch-content > .ant-spin-nested-loading,
.mc-flytwatch-content > .ant-spin-nested-loading > .ant-spin-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.mc-flytwatch-row {
  flex: 1;
  gap: 12px;
  margin: 0 !important;
}
.mc-flytwatch-row .ant-col {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 !important;
}
.mc-flytwatch-row .ant-col.mc-flytwatch-col-collapsed {
  flex-grow: 0;
}
.mc-flytwatch-header {
  margin: -24px;
  margin-bottom: 12px;
  border-bottom: 1px solid #d9d9d9;
  padding: 12px;
  background-color: #fff;
}
.mc-flytwatch-card {
  background-color: #fff;
  flex: 1;
  height: 100%;
}
.mc-flytwatch-card.mc-table-card {
  border-radius: 6px;
}
.mc-flytwatch-card .mc-table-card.mc-table-card-slide-in {
  animation: slide-in 700ms ease-out;
}
.mc-flytwatch-card .mc-table-card.mc-table-card-bordered {
  border-color: #909090;
  border-width: 1px;
  border-style: solid;
}
.mc-flytwatch-card.light-theme-only .mc-flytwatch-hl {
  color: yellow;
  background-color: black;
  border-radius: 15px;
  width: 21px;
  text-align: center;
  display: inline-block;
}
.mc-flytwatch-card .mc-enhanced-table.mc-enchanced-table-flexmode {
  flex: 1;
}
.mc-flytwatch-card-base:not(.light-theme-only).mc-table-card {
  background-color: #032a2a;
}
.mc-flytwatch-card-base:not(.light-theme-only).mc-table-card.mc-table-card-bordered {
  border-color: #098b8b;
}
.mc-flytwatch-card-base:not(.light-theme-only).mc-table-card .mc-table-card-header {
  border-bottom: 1px solid #098b8b;
  background: linear-gradient(0deg, #043838, #066161);
}
.mc-flytwatch-card-base:not(.light-theme-only).mc-table-card .mc-table-card-header:hover {
  background: linear-gradient(0deg, #054c4c, #087676);
}
.mc-flytwatch-card-base:not(.light-theme-only).mc-flytwatch-card-hidden {
  border: 1px solid #098b8b;
  background: linear-gradient(180deg, #043838, #066161);
}
.mc-flytwatch-card-base:not(.light-theme-only).mc-flytwatch-card-hidden:hover {
  background: linear-gradient(180deg, #054646, #076f6f);
}
.mc-flytwatch-card-base:not(.light-theme-only) .mc-enhanced-table .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background-color: #076868;
  border-bottom: 1px solid #098b8b !important;
}
.mc-flytwatch-card-base:not(.light-theme-only) .mc-table .ant-table-row:nth-child(odd) {
  background-color: #032a2a;
}
.mc-flytwatch-card-base:not(.light-theme-only) .mc-table .ant-table-row:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.1);
}
.mc-flytwatch-card-base:not(.light-theme-only) .ant-table-placeholder {
  background-color: #032a2a;
}
.mc-flytwatch-card-base:not(.light-theme-only) .mc-table a {
  color: #3bf2f2;
}
.mc-flytwatch-card-base:not(.light-theme-only) .mc-table a:hover {
  color: #76f6f6;
}
.mc-flytwatch-card-base.light-theme-only.mc-table-card.mc-table-card-bordered {
  border-color: #1fcaca;
}
.mc-flytwatch-card-base.light-theme-only.mc-table-card .mc-table-card-header {
  border-bottom: 1px solid #1fcaca;
  background: #1fcaca;
  color: white;
}
.mc-flytwatch-card-base.light-theme-only.mc-table-card .mc-table-card-header:hover {
  background: #41d2d2;
}
.mc-flytwatch-card-base.light-theme-only .mc-table-card-header-title {
  color: white;
}
.mc-flytwatch-card-base.light-theme-only.mc-flytwatch-card-hidden {
  border: 1px solid #1fcaca;
  background: #8fe5e5;
}
.mc-flytwatch-card-base.light-theme-only.mc-flytwatch-card-hidden:hover {
  background: #8fe5e5;
}
.mc-flytwatch-card-base.light-theme-only .mc-enhanced-table .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background-color: #1aacac;
  border-bottom: 1px solid #1aacac !important;
  color: white;
}
.mc-flytwatch-card-base.light-theme-only .mc-table .ant-table-row:nth-child(even) {
  background-color: #ddf7f7;
}
.mc-flytwatch-card-air.light-theme-only.mc-table-card.mc-table-card-bordered {
  border-color: #1c7ad1;
}
.mc-flytwatch-card-air.light-theme-only.mc-table-card .mc-table-card-header {
  border-bottom: 1px solid #1c7ad1;
  background: #1c7ad1;
  color: white;
}
.mc-flytwatch-card-air.light-theme-only.mc-table-card .mc-table-card-header:hover {
  background: #3e8ed8;
}
.mc-flytwatch-card-air.light-theme-only .mc-table-card-header-title {
  color: white;
}
.mc-flytwatch-card-air.light-theme-only.mc-flytwatch-card-hidden {
  border: 1px solid #1c7ad1;
  background: #8ebde8;
}
.mc-flytwatch-card-air.light-theme-only.mc-flytwatch-card-hidden:hover {
  background: #8ebde8;
}
.mc-flytwatch-card-air.light-theme-only .mc-enhanced-table .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background-color: #1868b2;
  border-bottom: 1px solid #1868b2 !important;
  color: white;
}
.mc-flytwatch-card-air.light-theme-only .mc-table .ant-table-row:nth-child(even) {
  background-color: #ddebf8;
}
.mc-flytwatch-card-check:not(.light-theme-only).mc-table-card {
  background-color: #2a1903;
}
.mc-flytwatch-card-check:not(.light-theme-only).mc-table-card.mc-table-card-bordered {
  border-color: #8b5309;
}
.mc-flytwatch-card-check:not(.light-theme-only).mc-table-card .mc-table-card-header {
  border-bottom: 1px solid #8b5309;
  background: linear-gradient(0deg, #382104, #613a06);
}
.mc-flytwatch-card-check:not(.light-theme-only).mc-table-card .mc-table-card-header:hover {
  background: linear-gradient(0deg, #4c2e05, #764708);
}
.mc-flytwatch-card-check:not(.light-theme-only).mc-flytwatch-card-hidden {
  border: 1px solid #8b5309;
  background: linear-gradient(180deg, #382104, #613a06);
}
.mc-flytwatch-card-check:not(.light-theme-only).mc-flytwatch-card-hidden:hover {
  background: linear-gradient(180deg, #462a05, #6f4207);
}
.mc-flytwatch-card-check:not(.light-theme-only) .mc-enhanced-table .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background-color: #683e07;
  border-bottom: 1px solid #8b5309 !important;
}
.mc-flytwatch-card-check:not(.light-theme-only) .mc-table .ant-table-row:nth-child(odd) {
  background-color: #2a1903;
}
.mc-flytwatch-card-check:not(.light-theme-only) .mc-table .ant-table-row:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.1);
}
.mc-flytwatch-card-check:not(.light-theme-only) .ant-table-placeholder {
  background-color: #2a1903;
}
.mc-flytwatch-card-check:not(.light-theme-only) .mc-table a {
  color: #f2a33b;
}
.mc-flytwatch-card-check:not(.light-theme-only) .mc-table a:hover {
  color: #f6bf76;
}
.mc-flytwatch-card-check.light-theme-only.mc-table-card.mc-table-card-bordered {
  border-color: #d68b29;
}
.mc-flytwatch-card-check.light-theme-only.mc-table-card .mc-table-card-header {
  border-bottom: 1px solid #d68b29;
  background: #d68b29;
  color: white;
}
.mc-flytwatch-card-check.light-theme-only.mc-table-card .mc-table-card-header:hover {
  background: #dc9c49;
}
.mc-flytwatch-card-check.light-theme-only .mc-table-card-header-title {
  color: white;
}
.mc-flytwatch-card-check.light-theme-only.mc-flytwatch-card-hidden {
  border: 1px solid #d68b29;
  background: #ebc594;
}
.mc-flytwatch-card-check.light-theme-only.mc-flytwatch-card-hidden:hover {
  background: #ebc594;
}
.mc-flytwatch-card-check.light-theme-only .mc-enhanced-table .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background-color: #b67623;
  border-bottom: 1px solid #b67623 !important;
  color: white;
}
.mc-flytwatch-card-check.light-theme-only .mc-table .ant-table-row:nth-child(even) {
  background-color: #f9eedf;
}
.mc-flytwatch-hl {
  color: #1890ff;
  font-weight: bold;
}
.mc-flytwatch-card-hidden {
  border-radius: 4px;
  height: 100%;
  background: #e6e6e6;
  border: 1px solid #c3c3c3;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: 10em;
}
.mc-flytwatch-card-hidden-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
.mc-flytwatch-card-header {
  cursor: pointer;
}
.mc-flytwatch-card-hidden:hover,
.mc-flytwatch-card-header:hover {
  background: linear-gradient(0deg, #dadada, #f2f2f2);
  border-color: #c3c3c3;
  transition: background 250ms ease-out, border-color 250ms ease-out;
}
.mc-flytwatch-details-drawer .ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
}
.mc-flytwatch-details-drawer .ant-drawer-body {
  padding: 6px;
  flex: 1;
}
.mc-flytwatch-details-drawer .ant-drawer-header {
  border-bottom-width: 0;
  padding-bottom: 6px;
}
.mc-flytwatch-popout-wrapper.mc-popout-wrapper {
  padding: 6px;
}
.mc-flytwatch-popout-wrapper .ant-descriptions-title {
  margin-bottom: 6px;
}
