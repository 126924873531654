/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-report-ui-header.ant-layout-header {
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
  padding: 0 12px;
}
.mc-report-ui-sider.ant-layout-sider {
  border-right: 1px solid #d9d9d9;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.65);
}
.mc-report-ui-sider.mc-report-ui-sider-hidden.ant-layout-sider {
  display: none;
}
.mc-report-ui-sider.ant-layout-sider > .ant-layout-sider-children {
  padding: 0;
  display: flex;
  flex-direction: column;
}
.mc-report-ui-title {
  margin: 0;
}
.mc-report-ui-form-title {
  border-bottom: 1px solid #d9d9d9;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 12px;
}
.ant-page-header.mc-report-ui-form-title {
  border-bottom: 1px solid #d9d9d9;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0 0 12px 0;
  margin-bottom: 0.5em;
}
.ant-page-header.mc-report-ui-form-title .ant-page-header-heading-title {
  font-size: 1.17em;
}
.mc-report-ui-sider-body {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px 12px;
}
.mc-report-ui-criteria-display-btn {
  line-height: 3rem;
  height: 3rem;
  min-height: 3rem;
  border-radius: 0 !important;
}
.mc-report-ui-criteria-display-btn:not(:hover):not(:active) {
  border-color: transparent !important;
  border-top: 1px solid #d9d9d9 !important;
}
.mc-report-ui-criteria-display-btn-vertical.ant-btn {
  width: 2rem;
  min-width: 2rem;
  line-height: 2rem;
  height: 100%;
  border-radius: 0 !important;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mc-report-ui-criteria-display-btn-vertical > span {
  writing-mode: vertical-rl;
}
.mc-report-ui-criteria-display-btn-vertical:not(:hover):not(:active) {
  border-color: transparent !important;
  border-right: 1px solid #d9d9d9 !important;
}
