/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-table .ant-table-row:nth-child(odd) {
  background: #fff;
}
.mc-table tr:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.mc-table tr:last-child > td:first-child {
  border-bottom-left-radius: 3px;
}
.mc-table tr:last-child > td:last-child {
  border-bottom-right-radius: 3px;
}
.mc-table .ant-table-thead > tr:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.mc-table .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 3px;
}
.mc-table .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 3px;
}
.mc-table .ant-table-small .ant-table-row {
  margin: 0 -8px;
  padding: 0 8px;
}
.mc-table .ant-table-small .ant-table-tbody > tr > td {
  border-bottom: none;
}
.mc-table .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}
.mc-table .bp3-table-cell-ledger-odd:not([class*="bp3-intent"]):not(.cell-isn-status),
.mc-table .ant-table-row:nth-child(even) {
  background: #e9ecee;
}
.mc-table .bp3-table-cell-ledger-even:not([class*="bp3-intent"]):not(.cell-isn-status),
.mc-table .ant-table-row:nth-child(odd) {
  background: #fff;
}
.mc-table .bp3-table-cell:not([class*="bp3-intent"]),
.mc-table .bp3-table-cell:not(.cell-isn-status) {
  box-shadow: inset -1px 0 0 rgba(16, 22, 26, 0.15);
}
.mc-table .bp3-table-cell[class*="bp3-intent"],
.mc-table .bp3-table-cell.cell-isn-status {
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15);
}
.mc-table .bp3-table-quadrant,
.mc-table .bp3-table-cell-client {
  background: #f5f8fa;
}
.mc-table .bp3-table-column-headers,
.mc-table .bp3-table-row-headers,
.mc-table .bp3-table-menu {
  background: #f5f8fa;
}
.mc-table .bp3-table-quadrant-main .bp3-table-cell-client {
  background: #fff;
}
.mc-table .bp3-table-bottom-container,
.mc-table .bp3-table-top-container,
.mc-table .bp3-table-column-headers,
.mc-table .bp3-table-row-headers {
  color: rgba(0, 0, 0, 0.65);
}
.mc-table .bp3-table-row-headers .bp3-table-header {
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15);
}
.mc-table .bp3-table-quadrant-left .bp3-table-cell.bp3-table-last-in-row {
  box-shadow: inset 1px 0 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15);
}
.mc-table .bp3-table-quadrant-left .bp3-table-cell.bp3-table-last-in-row.bp3-table-last-in-column {
  box-shadow: inset 1px 0 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15), inset 0 -1px 0 rgba(16, 22, 26, 0.15);
}
.mc-table .bp3-table-cell.bp3-table-last-in-column {
  box-shadow: inset -1px 0 0 rgba(16, 22, 26, 0.15), inset 0 -1px 0 rgba(16, 22, 26, 0.15);
}
.mc-table .bp3-table-menu {
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15);
}
.mc-table.bp3-table-selection-enabled .bp3-table-header:hover::before,
.mc-table .bp3-table-header.bp3-table-header-active::before {
  background-color: #dddfe1;
}
.mc-table .bp3-table-overlay-layer {
  z-index: 21;
}
.mc-table .bp3-table-column-headers .bp3-table-header {
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15);
}
.mc-table:focus {
  outline: none;
}
.mc-table > .ant-table-content .ant-table-header {
  background-color: #fff;
}
.mc-table .ant-table-row {
  white-space: nowrap;
}
.mc-table.mc-table-scroll-auto .ant-table-body {
  overflow: auto !important;
}
.mc-table.mc-table-scroll-auto .ant-table-header {
  margin-right: -10px;
}
.mc-table.mc-table-scroll-auto-x .ant-table-body {
  overflow-x: auto !important;
}
.mc-table.mc-table-scroll-auto-x .ant-table-header {
  margin-right: -10px;
}
.mc-table.mc-table-scroll-auto-y .ant-table-body {
  overflow-y: auto !important;
}
.mc-table.mc-table-scroll-auto-y .ant-table-header {
  margin-right: -10px;
}
.mc-table.mc-table-no-border .ant-table {
  border: none;
  border-radius: 0;
}
.mc-table.mc-table-fill-height {
  height: 100%;
}
.mc-table.mc-table-fill-height .ant-spin-nested-loading,
.mc-table.mc-table-fill-height .ant-table,
.mc-table.mc-table-fill-height .ant-table-content,
.mc-table.mc-table-fill-height .ant-table-scroll,
.mc-table.mc-table-fill-height .ant-table-body {
  height: 100%;
}
.mc-table.mc-table-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.mc-table.mc-table-center-cell-text-vertically .bp3-table-cell,
.mc-table.mc-table-center-cell-text-vertically .bp3-table-header {
  display: flex;
  align-items: center;
}
.mc-table.mc-table-cell-text-size-large .bp3-table-cell {
  font-size: 14px;
}
.mc-table.mc-table-header-nowrap .ant-table-header,
.mc-table.mc-table-header-nowrap .ant-table-thead {
  white-space: nowrap;
}
.mc-table.mc-table-with-tfoot table > tfoot > tr > td {
  border-top: 1px solid #d9d9d9;
}
