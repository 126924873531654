/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-live-card {
  margin: 12px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px;
  position: relative;
}
.mc-live-card .mc-live-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.mc-live-card .mc-live-content .ant-spin-nested-loading {
  flex: 1;
}
.mc-live-card .mc-live-content .ant-spin-nested-loading > .ant-spin-container {
  height: 100%;
}
.mc-live-card .mc-live-content.mc-live-content-padded {
  padding: 12px;
}
.mc-live-card .mc-live-header {
  border-bottom: 1px solid #c3c3c3;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: linear-gradient(0deg, #e6e6e6, #fff);
}
.mc-live-card .mc-live-header .ant-btn:not(:hover):not(:focus):not(.ant-btn-primary) {
  border-color: #c3c3c3;
}
.mc-live-card .mc-live-header .ant-btn:not(.ant-btn-primary),
.mc-live-card .mc-live-header .ant-btn:hover:focus:not(.ant-btn-primary) {
  background-color: #fff;
}
.mc-live-card .mc-live-content {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.mc-live-card .mc-live-content-has-table .mc-table .ant-table {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: none;
}
.mc-live-card .ant-table-header > .ant-table-fixed {
  border: none;
}
.mc-live-card thead > tr {
  background: #e6e6e6;
}
.mc-live-card thead > tr > th {
  border-bottom: 1px solid #c3c3c3 !important;
}
.mc-live-card thead > tr:hover {
  background: #e6e6e6;
}
.mc-live-card .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 0;
}
.mc-live-card .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 0;
}
.mc-live-card .ant-table-small > .ant-table-content .ant-table-header {
  border-radius: 0;
}
.mc-live-card.mc-live-card-slide-in {
  animation: slide-in 700ms ease-out;
}
.mc-live-card.mc-live-card-bordered {
  border-color: #c3c3c3;
  border-width: 1px;
  border-style: solid;
}
@keyframes slide-in {
  from {
    top: 2rem;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
