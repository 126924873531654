/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-layout-header.scheduling-main-header {
  background-color: #f2f2f2;
  padding: 10px 16px;
  display: flex;
  border-bottom: 1px solid #d9d9d9;
  height: unset;
  line-height: 46px;
}
.ant-layout-header.scheduling-main-header .scheduling-main-header-divider {
  height: 3rem;
  margin: 0 1rem;
  background: #d2d2d2;
}
