
.phase-1-history-editor-page {
  &-spinner.ant-spin-nested-loading{
    flex: 1;
    min-height: 0;
  }

  &-spinner.ant-spin-nested-loading > .ant-spin-container
  {
    height: 100%;
  }

  &-layout {
    display: flex;
    height: 100%;
  }
}