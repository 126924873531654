/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.notes-for-pilot-editor .ant-collapse-header {
  cursor: default !important;
}
.notes-for-pilot-editor .ant-collapse-content-box {
  padding: 0;
}
.notes-for-pilot-editor .notes-for-pilot-editor-collapse-handle {
  cursor: pointer;
  padding: 6px;
  position: relative;
  transition: all 150ms;
}
.notes-for-pilot-editor .notes-for-pilot-editor-collapse-handle:hover {
  background-color: #e6f7ff;
}
.notes-for-pilot-editor .notes-for-pilot-editor-collapse-handle-icon {
  position: absolute;
  left: 10px;
  top: 10px;
}
.notes-for-pilot-editor .notes-for-pilot-editor-collapse-handle-body {
  padding-left: 26px;
}
.notes-for-pilot-editor-note-assigner .ant-list-item,
.notes-for-pilot-editor .notes-for-pilot-editor-collapse-handle .ant-list-item {
  padding: 6px 0 !important;
}
.notes-for-pilot-editor-note-assigner-selector-menu .ant-select-dropdown-menu-item {
  white-space: break-spaces !important;
}
