/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-table.mc-table-passengers .mc-table-passengers-row-overnight {
  color: rgba(255, 255, 255, 0.85);
}
.mc-table.mc-table-passengers .mc-table-passengers-row-overnight:nth-child(even) {
  background: #800080;
}
.mc-table.mc-table-passengers .mc-table-passengers-row-overnight:nth-child(odd) {
  background: #6d006d;
}
.mc-table.mc-table-passengers .mc-table-passengers-row-overnight a {
  color: rgba(255, 255, 255, 0.75);
  font-weight: bold;
}
.mc-table.mc-table-passengers .mc-table-passengers-row-expected {
  color: rgba(255, 255, 255, 0.85);
}
.mc-table.mc-table-passengers .mc-table-passengers-row-expected:nth-child(even) {
  background: #006E66;
}
.mc-table.mc-table-passengers .mc-table-passengers-row-expected:nth-child(odd) {
  background: #005e57;
}
.mc-table.mc-table-passengers .mc-table-passengers-row-expected a {
  color: rgba(255, 255, 255, 0.75);
  font-weight: bold;
}
