/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.scheduling-steps-header {
  background-color: #fff;
  height: auto !important;
  padding: 0.5rem 1rem !important;
  border-bottom: 1px solid #d9d9d9;
}
.scheduling-steps-header .ant-breadcrumb > span a {
  color: rgba(0, 0, 0, 0.45);
}
.scheduling-steps-header .ant-breadcrumb > span a:hover {
  color: rgba(0, 0, 0, 0.65);
}
.scheduling-steps-header .ant-breadcrumb > span:last-child a {
  color: rgba(0, 0, 0, 0.45);
}
.scheduling-steps-header .ant-breadcrumb > span:last-child a:hover {
  color: rgba(0, 0, 0, 0.65);
}
