/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-border-left {
  border-left: 1px solid #d9d9d9;
}
.mc-border-right {
  border-right: 1px solid #d9d9d9;
}
.mc-border-top {
  border-top: 1px solid #d9d9d9;
}
.mc-border-bottom {
  border-bottom: 1px solid #d9d9d9;
}
.mc-border-vertical {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
.mc-border-horizontal {
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
}
.mc-border {
  border: 1px solid #d9d9d9;
}
