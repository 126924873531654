
.mc-feedback-mood.ant-radio-group {
    width: 100%;
    display: flex;
    height: auto;

    .ant-radio-button-wrapper {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;

        span {
            display: flex;
            align-items: center;
            line-height: 57px;

            > .mc-icon {
                margin-right: 0.5rem;
                font-size: 2rem;
            }

        }
        
        .ant-radio-button {
            display: flex;
            align-items: center;
        }
    }
}