/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-location-marker {
  position: relative;
}
.mc-location-marker-icon {
  position: absolute;
  font-size: 1.5rem;
  top: -21px;
  left: -9px;
  color: red;
}
.mc-location-marker-label-container {
  position: absolute;
  top: 0.5rem;
  left: -6rem;
  width: 12rem;
  max-width: 12rem;
  min-width: 12rem;
  pointer-events: none;
}
.mc-location-marker-label {
  text-align: center;
  color: black;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  font-weight: 500;
}
.mc-location-marker-sublabel {
  text-align: center;
  color: black;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  font-weight: 500;
  font-size: 0.7rem;
}
.mc-location-marker-icon.mc-location-marker-icon-start {
  color: #52c41a;
}
.mc-location-marker-icon.mc-location-marker-icon-end {
  color: #f5222d;
}
