/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.list-attribute {
  line-height: 22px;
  margin-bottom: 7;
}
.list-attribute-label {
  width: 5rem;
  text-align: right;
  margin-right: 1rem;
  display: inline-block;
  font-weight: 400;
}
