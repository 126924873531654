/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.reporting-sider-section {
  padding: 1rem;
}
.reporting-sider-section h1,
.reporting-sider-section h2,
.reporting-sider-section h3,
.reporting-sider-section h4,
.reporting-sider-section h5,
.reporting-sider-section h6 {
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  text-transform: uppercase;
}
