/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.whitelist-left-panel,
.whitelist-right-panel {
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 0;
}
.whitelist-left-panel {
  border-right-width: 1px;
}
.whitelist-right-panel {
  border-left-width: 1px;
}
.whitelist-person-search-col {
  border-color: #d9d9d9;
  border-width: 0 1px 1px 0;
  border-style: solid;
  padding: 12px 0 0 8px;
  min-height: 40rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.whitelist-left-panel-conditional {
  background: shade(@component-background, 10%);
  flex: 1;
  border-top: 1px solid #d9d9d9;
}
