
div.combined-search-wrapper {
    display: flex;
    flex-direction: column;
}

div.combined-search-input-wrapper {
    padding: 1rem;
}

div.combined-search-table {
    flex: 1;
}

div.combined-search-tabs {
    flex: 1;
}

div.combined-search-tabs > .ant-tabs-bar {
    margin: 0;
}


div.combined-search-tabs > .ant-tabs-bar.ant-tabs-card-bar {
    padding: 0 0.4rem;
}

div.combined-search-tabs .ant-tabs-nav-container {
    padding-top: 0.4rem;
    height: 46px !important;
}

div.combined-search-tabs .ant-tabs-extra-content {
    line-height: 45px;
}