/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-scheduled-groups-list-pill {
  padding: 6px 10px;
  border-radius: 4px;
}
.mc-scheduled-groups-list-pill-no-pax {
  color: #fff;
  background-color: #cf1322;
  border-top: 1px solid #e78991;
  border-right: 1px solid #e0666f;
  border-left: 1px solid #db4e59;
  border-bottom: 1px solid #680a11;
}
.mc-scheduled-groups-list-pill-some-pax {
  color: #000;
  background-color: #d4b106;
  border-top: 1px solid #ead883;
  border-right: 1px solid #e3cc5d;
  border-left: 1px solid #dfc544;
  border-bottom: 1px solid #6a5903;
}
.mc-scheduled-groups-list-pill-all-pax {
  color: #fff;
  background-color: #389e0d;
  border-top: 1px solid #9ccf86;
  border-right: 1px solid #7ec062;
  border-left: 1px solid #6ab64a;
  border-bottom: 1px solid #1c4f07;
}
