/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.reporting-interface-header {
  background: #fff;
  padding: 0 1rem;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #e8e8e8;
}
.reporting-interface-content {
  padding: 1rem;
  overflow: auto;
  background: #fff;
}
