/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-scheduled-groups-calendar-event,
.mc-scheduled-groups-calendar-event.ant-btn {
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #1890ff, transparent);
  transition: background 1s;
}
.mc-scheduled-groups-calendar-event-left,
.mc-scheduled-groups-calendar-event.ant-btn-left {
  flex: 1;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}
.mc-scheduled-groups-calendar-event:hover,
.mc-scheduled-groups-calendar-event.ant-btn:hover {
  background-position-x: 100%;
  background-repeat: no-repeat;
}
.mc-scheduled-groups-calendar-pill {
  padding: 0 5px;
  border-radius: 4px;
  font-size: 0.8rem;
}
.mc-scheduled-groups-calendar-pill-no-pax {
  color: #fff;
  background-color: #cf1322;
  border-top: 1px solid #e78991;
  border-right: 1px solid #e0666f;
  border-left: 1px solid #db4e59;
  border-bottom: 1px solid #680a11;
}
.mc-scheduled-groups-calendar-pill-some-pax {
  color: #fff;
  background-color: #d4b106;
  border-top: 1px solid #ead883;
  border-right: 1px solid #e3cc5d;
  border-left: 1px solid #dfc544;
  border-bottom: 1px solid #6a5903;
}
.mc-scheduled-groups-calendar-pill-all-pax {
  color: #fff;
  background-color: #389e0d;
  border-top: 1px solid #9ccf86;
  border-right: 1px solid #7ec062;
  border-left: 1px solid #6ab64a;
  border-bottom: 1px solid #1c4f07;
}
.mc-scheduled-groups-calendar-tooltip-tr-no-pax {
  padding-bottom: 3px;
}
.mc-scheduled-groups-calendar-tooltip-tr-no-pax > th,
.mc-scheduled-groups-calendar-tooltip-tr-no-pax > td {
  color: #fff;
  background-color: #cf1322;
}
.mc-scheduled-groups-calendar-tooltip-tr-some-pax {
  padding-bottom: 3px;
}
.mc-scheduled-groups-calendar-tooltip-tr-some-pax > th,
.mc-scheduled-groups-calendar-tooltip-tr-some-pax > td {
  color: #fff;
  background-color: #d4b106;
}
.mc-scheduled-groups-calendar-tooltip-tr-all-pax {
  padding-bottom: 3px;
}
.mc-scheduled-groups-calendar-tooltip-tr-all-pax > th,
.mc-scheduled-groups-calendar-tooltip-tr-all-pax > td {
  color: #fff;
  background-color: #389e0d;
}
@media screen and (max-width: 1340px) {
  .mc-scheduled-groups-calendar-pill {
    width: 17px !important;
    display: block;
    text-align: center;
    padding: 0;
  }
  .mc-scheduled-groups-calendar-event-right {
    display: flex !important;
  }
}
