/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-live-cargo {
  margin: -24px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}
.mc-live-cargo .mc-live-header-contain-left {
  min-width: 0;
  position: relative;
}
.mc-live-cargo .mc-live-header-contain-right {
  flex-grow: 0;
  white-space: nowrap;
}
.mc-live-cargo-mobile-tabs {
  display: none;
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
  position: fixed;
  height: 57px;
  bottom: 0;
  width: 100%;
  overflow: hidden;
}
@media screen and (max-width: 1048px) {
  .mc-live-cargo .mc-live-header-contain-left > .mc-live-header-tabs {
    position: absolute;
    width: 100%;
  }
}
@media screen and (max-width: 1048px) and (min-width: 560px) {
  .mc-live-cargo .mc-live-header-title-wrapper {
    display: none;
  }
}
@media screen and (max-width: 560px) {
  .mc-live-cargo .mc-live-header-contain-left > .mc-live-header-tabs {
    display: none;
  }
  .mc-live-cargo {
    height: calc(100% + 48px - 57px);
  }
  .mc-live-cargo-mobile-tabs {
    display: flex;
    justify-content: center;
    z-index: 1;
  }
  .mc-live-cargo-mobile-tabs .ant-tabs-bar {
    margin: 0;
    border-color: #fff;
  }
  .mc-live-cargo-mobile-tabs .ant-tabs-tab {
    margin: 0;
    line-height: 33px;
  }
  .mc-live-cargo .mc-live-header-contain-left > .mc-live-header-title-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mc-live-cargo .mc-live-header-contain-left > .mc-live-header-title-wrapper > .mc-live-header-title {
    font-size: 16px;
  }
  .mc-live-cargo .mc-live-header-contain-left > .mc-live-header-title-wrapper > * {
    text-overflow: ellipsis;
  }
}
