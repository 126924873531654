
.mc-covid-vax-dates-form {

    &-item:last-of-type {
        margin-bottom: 0;
    }

    &-item:not(:first-of-type){
        .ant-form-item-label {
            display: none;
        }
    }

    &-item {
        display: flex;
        gap: 6px;
        margin-bottom: 6px;
    }
}

.mc-covid-vax-dates-form .ant-form-item {
    margin-bottom: 0;

    .ant-form-item-label {
        line-height: initial;
    }

    .ant-form-explain {
        max-width: 11rem;
    }
}

.mc-covid-vax-dates-form-item-with-clear .ant-form-item-children {
    display: flex;
    align-items: center;
    height: 40px;
}