/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-masterdata-header {
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 0.5rem;
  line-height: 2rem;
}
@media screen and (max-width: 560px) {
  .mc-masterdata-hide-small {
    display: none;
  }
}
