@import '../../theme/layout.less';

.mc-global-filter-container.ant-row-flex {
    align-items: center;
    flex-wrap: nowrap;
}

.mc-global-filter-title {
    margin-bottom: 0;
    white-space: nowrap;
}

.mc-global-filter-title-icon.anticon {
    display: none;
    margin-left: 6px;
}

.mc-global-filter-fields-wrapper {
    display: flex;
}

.mc-global-filter-title-wrapper-small.ant-btn {
    display: none;
    align-items: center;
}

@media screen and (max-width: @screen-size-medium) {
    .mc-global-filter-container {
        flex-direction: column;
        justify-content: center;
    }
    .mc-global-filter-divider {
        display: none;
    }
    .mc-global-filter-title {
        display: inline;
    }
    .mc-global-filter-title-wrapper {
        display: flex;
        justify-content: center;
    }
    .mc-global-filter-title-icon {
        display: inline-block !important;
    }

    .mc-global-filter-fields-wrapper {
        display: none;
    }

    .mc-global-filter-title-wrapper {
        display: none;
    }

    .mc-global-filter-title-wrapper-small.ant-btn {
        display: flex;
    }
}