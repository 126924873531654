:root {
    --report-border-color: #535353;
    --report-header-color: #e5e5e5;
}

.mc-report table,
.mc-report thead > tr > th,
.mc-report tbody > tr > td {
    border-color: var(--report-border-color) !important;
    border-radius: 0 !important;
    white-space: nowrap;
}

.mc-report .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
    background: var(--report-header-color);
}

.mc-report .ant-table-small table {
    padding: 0 !important;
    border: 1px solid var(--report-border-color) !important;
}

.mc-report thead > tr > th {
    background: var(--report-header-color);
    color: #000000;
}
.mc-report tbody > tr > td,
.mc-report tbody > tr {
    background: initial !important;
}

.mc-report thead > tr:hover,
.mc-report tbody > tr:hover {
    background: initial !important;
}


.mc-report .ant-table-row-expand-icon-cell, 
.mc-report .ant-table-expand-icon-th,
.mc-report .ant-table-expanded-row > td:first-child {
  display: none;
}

.mc-report .ant-table-small {
    border: none;
}

.mc-report .ant-table-body {
    margin: 0 !important;
}