/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-override-card.ant-card {
  background-color: #009684;
  border-color: #1eceb9;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.122);
  color: white;
}
.mc-override-card.ant-card .ant-card-head {
  border-color: #1eceb9;
  border-bottom-width: 2px;
  background: linear-gradient(0deg, rgba(0, 150, 132, 0.541), transparent);
  color: white;
}
.mc-override-card.ant-card .ant-card-body {
  background: linear-gradient(0deg, #01796b, transparent);
  color: rgba(255, 255, 255, 0.9);
}
.mc-override-card.ant-card .mc-redwhiteoverride {
  padding: 1px 3px;
  border-radius: 4px;
  background-color: gray;
  color: white;
}
.mc-override-card.ant-card .ant-row {
  display: flex;
  align-items: stretch;
}
.mc-override-card.ant-card .ant-row .ant-col-17 {
  display: flex;
  align-items: flex-end;
}
.mc-override-card.ant-card .ant-row:not(:last-child) {
  padding-bottom: 6px;
}
