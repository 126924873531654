
.mc-centered-loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &-indicator-wrapper {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}