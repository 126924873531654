/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.reporting-details-content {
  background: #fff;
  padding: 1rem;
  overflow: auto;
}
.reporting-details-criteria-sider {
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-width: 0 0 0 1px;
  padding: 1rem;
}
