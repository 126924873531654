.scheduling-events {
    list-style: none;
    margin: 0;
    padding: 0;
}

.scheduling-events li {
    margin-bottom: 3px;
}

.scheduling-events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px;
}

.ant-fullcalendar-date.scheduling-date-no-data:hover, .ant-fullcalendar-date.scheduling-date-no-data:active {
    cursor: default;
    background: transparent;
}

.scheduling-date-no-data > .ant-fullcalendar-value {
    cursor: default;
}