/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-map .maplibregl-ctrl-group {
  overflow: hidden;
}
.mc-map .maplibregl-ctrl-group button.mc-map-layer-btn,
.mc-map .maplibregl-ctrl-group button.mc-map-layer-btn:hover,
.mc-map .maplibregl-ctrl-group button.mc-map-layer-btn:active {
  background-color: #1682e6;
  color: white;
}
.mc-map .maplibregl-ctrl.maplibregl-ctrl-attrib {
  background-color: rgba(127, 127, 127, 0.6);
}
.mc-map .maplibregl-ctrl.maplibregl-ctrl-attrib a {
  color: white;
}
