.mc-icon-smile .mc-icon-smile-eye {
  transform: scale(1, 1);
}
@keyframes smile-blink {
  0% .mc-icon-smile .mc-icon-smile-eye {
    transform: scale(1, 1);
  }
  50% .mc-icon-smile .mc-icon-smile-eye {
    transform: scale(1, 0);
  }
  100% .mc-icon-smile .mc-icon-smile-eye {
    transform: scale(1, 1);
  }
}
.mc-feedback-button .mc-icon-smile,
.mc-feedback-button .anticon.mc-icon-smile {
  transform: scale(1.4) translate(-3px, 0px);
  transition: transform 250ms ease-out;
}
.mc-feedback-button .mc-icon-smile-eye-right {
  animation: smile-wink 0.25s ease infinite;
  transform-origin: center;
}
.mc-feedback-button:hover .mc-icon-smile {
  transform: scale(1.7) translate(-2.6px, 0px);
}
@keyframes smile-grow {
  0% .mc-icon-smile {
    transform: scale(1.4) translate(-5px, 0px);
  }
  80% .mc-icon-smile {
    transform: scale(1.7) translate(-5px, 0px);
  }
  100% .mc-icon-smile {
    transform: scale(1.6) translate(-5px, 0px);
  }
}
