/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.phase-2-scheduler-icon-container {
  text-align: center;
}
.phase-2-scheduler-content > div {
  display: flex;
  flex-direction: column;
  position: relative;
}
.phase-2-scheduler-content > div > *:not(.phase-2-scheduler-toolbar) {
  margin: 12px 12px 0 12px;
}
.phase-2-scheduler-content > div > *:not(.phase-2-scheduler-toolbar):last-child {
  margin: 12px 12px 12px 12px;
}
.phase-2-scheduler-spinner.ant-spin-nested-loading {
  flex: 1;
  min-height: 0;
}
.phase-2-scheduler-spinner.ant-spin-nested-loading > .ant-spin-container {
  height: 100%;
}
.phase-2-scheduler-beta-tag {
  background-color: #52c41a;
  color: #fff;
  padding: 2px;
  border-radius: 4px;
  font-size: 1.3rem;
}
.phase-2-scheduler-layout {
  display: flex;
  height: 100%;
}
.phase-2-scheduler-sider {
  background-color: #fff;
  border-right: solid #d9d9d9;
  border-right-width: 1px;
  overflow: hidden;
}
.phase-2-scheduler-sider-collapsed {
  border-right-width: 0;
}
.phase-2-scheduler-sider-collapsed > .phase-2-scheduler-left-panel {
  opacity: 0;
}
.phase-2-scheduler-left-panel {
  min-width: 450px;
  opacity: 1;
  transition: opacity 0.2s;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.phase-2-scheduler-left-panel-content {
  flex: 1;
}
.phase-2-scheduler-left-panel .ant-tabs.ant-tabs-card .ant-tabs-extra-content {
  line-height: 35px;
}
.phase-2-scheduler-toolbar {
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 7px 12px;
  position: sticky;
  top: 0;
  z-index: 2;
}
.phase-2-scheduler-table-header {
  background-color: #cccccc;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 8px;
}
.phase-2-scheduler-selected-personnel {
  min-height: fit-content;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  margin: 6px;
}
.phase-2-scheduler-selected-personnel-header {
  padding: 3px 12px;
  border-bottom: 1px solid #d9d9d9;
}
.phase-2-scheduler-personnel-select .ant-select-search__field {
  display: none;
}
.phase-2-scheduler-personnel-select .ant-select-selection__rendered {
  margin: 0;
}
.phase-2-scheduler-personnel-select .ant-select-selection,
.phase-2-scheduler-personnel-select .ant-select-focused .ant-select-selection {
  cursor: default;
  border: none !important;
  outline: none !important;
  width: 100%;
}
.phase-2-scheduler-personnel-select-wrapper {
  max-height: 6rem;
  min-height: 3rem;
  overflow: auto;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
}
.phase-2-scheduler-table:not(:first-child) {
  border-top: 1px solid #d9d9d9;
}
.phase-2-scheduler-table thead th {
  border-radius: 0 !important;
  background-color: #fafafa !important;
}
.phase-2-scheduler-table td > .phase-2-scheduler-table-on-flight {
  border-radius: 8px;
  background: linear-gradient(to right, #237804 10%, transparent);
  padding: 0.25rem;
  display: block;
}
.phase-2-scheduler-table td > .phase-2-scheduler-table-on-flight,
.phase-2-scheduler-table td > .phase-2-scheduler-table-on-flight > .ant-btn,
.phase-2-scheduler-table td > .phase-2-scheduler-table-on-flight > a {
  color: #fff;
}
.phase-2-scheduler-table td > .phase-2-scheduler-table-on-flight > .ant-btn:hover > span {
  text-decoration: underline;
}
.phase-2-scheduler-table-row[disabled],
.phase-2-scheduler-table-row[disabled] *:not(.ant-btn) {
  cursor: not-allowed;
}
.phase-2-scheduler-table-row {
  cursor: pointer;
}
.phase-2-scheduler-table-collapse-panel .ant-collapse-header {
  transition: none !important;
}
.phase-2-scheduler-table-collapse-panel-has-selection .ant-collapse-header,
.phase-2-scheduler-table-collapse-panel-has-on-flight .ant-collapse-header {
  padding-left: 6px !important;
}
.phase-2-scheduler-table-collapse-panel-counter-wrapper {
  display: none;
  height: 38px;
}
.phase-2-scheduler-table-collapse-panel-has-selection .phase-2-scheduler-table-collapse-panel-counter-wrapper,
.phase-2-scheduler-table-collapse-panel-has-on-flight .phase-2-scheduler-table-collapse-panel-counter-wrapper {
  display: flex;
  align-items: center;
  border-right: 1px solid #d9d9d9;
}
.phase-2-scheduler-table-collapse-panel-has-selection .ant-collapse-arrow,
.phase-2-scheduler-table-collapse-panel-has-on-flight .ant-collapse-arrow {
  display: none !important;
}
.phase-2-scheduler-table-collapse-panel-counter-wrapper .phase-2-scheduler-table-collapse-panel-counter {
  margin-right: 13.5px;
  margin-left: 6px;
}
.phase-2-scheduler-table-collapse-panel-counter-wrapper .phase-2-scheduler-table-collapse-panel-counter > sup {
  background-color: #1890ff;
  color: #fff;
}
.phase-2-scheduler-table-collapse-panel-has-on-flight .phase-2-scheduler-table-collapse-panel-counter-wrapper .phase-2-scheduler-table-collapse-panel-counter > sup {
  background-color: #237804;
}
.phase-2-scheduler-table-collapse-panel-tooltip-table th {
  padding-right: 12px;
}
.phase-2-scheduler-table-collapse-panel-tooltip-table th::after {
  content: ":";
}
.phase-2-scheduler-table-collapse-panel-tooltip-table tbody > tr:not(:last-child) {
  border-bottom: 1px solid #d9d9d9;
}
.phase-2-scheduler-table-collapse-panel .phase-2-scheduler-table-collapse-panel-header {
  display: flex;
  align-items: center;
  flex-shrink: 1;
  flex-grow: 1;
  min-width: 0;
  position: relative;
  overflow: hidden;
  transition: flex-shrink ease-out 250ms;
}
.phase-2-scheduler-table-collapse-panel .phase-2-scheduler-table-collapse-panel-header::after {
  content: "";
  position: absolute;
  width: 60px;
  right: 0;
  height: 100%;
  background: linear-gradient(to right, transparent, #fafafa);
}
.phase-2-scheduler-table-collapse-panel .phase-2-scheduler-table-collapse-panel-header:hover {
  flex-shrink: 0;
  max-width: 100%;
}
.phase-2-scheduler-table-collapse-panel .phase-2-scheduler-table-collapse-panel-header:hover::after {
  display: none;
}
.phase-2-scheduler-table-collapse-panel > .ant-collapse-header {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 6px !important;
  display: flex;
  align-items: center;
  background-color: #fafafa;
}
.phase-2-scheduler-table-collapse-panel > .ant-collapse-header:has(.phase-2-scheduler-table-collapse-panel-header:hover) > .ant-collapse-extra {
  flex-shrink: 1;
}
.phase-2-scheduler-table-collapse-panel > .ant-collapse-header > .ant-collapse-extra {
  float: inherit;
  white-space: nowrap;
  flex-shrink: 0;
  overflow: hidden;
}
.phase-2-scheduler-table-collapse-panel-section {
  border-right: 1px solid #d9d9d9;
  padding: 8px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.phase-2-scheduler-table-collapse-panel-section:first-of-type {
  border-left: 1px solid #d9d9d9;
  margin-left: 6px;
}
.phase-2-scheduler-table-collapse-panel-section:last-of-type {
  border-right-width: 0;
}
.phase-2-scheduler-table-collapse.ant-collapse {
  border: none;
  background-color: transparent;
}
.phase-2-scheduler-table-collapse .ant-collapse-item {
  border: 1px solid #d9d9d9;
  border-radius: 4px !important;
  overflow: hidden;
}
.phase-2-scheduler-table-collapse .ant-collapse-item:not(:last-child) {
  margin-bottom: 12px;
}
.phase-2-scheduler-table-collapse .ant-table-small {
  border: none;
}
.phase-2-scheduler-table-collapse .ant-table-small .ant-table-body {
  margin: 0;
}
.phase-2-scheduler-table-collapse .ant-collapse-content-box {
  padding: 0;
}
.phase-2-scheduler-cargo-footer {
  border-top: 1px solid #d9d9d9;
  padding: 12px;
  background: #fff;
}
.phase-2-scheduler-messenger-wrapper {
  padding: 0 6px;
  background-color: #fafafa;
  width: 10rem;
}
