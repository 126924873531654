
.mc-route-editor {

  & .ant-table-row {
    line-height: 25px !important;
  }

  & .ant-table-row &-actions {
    display: none;
    float: right;
  }

  & .ant-table-row:hover &-actions {
    display: inline-block;
  }
}