/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-flytwatch-aircraft-details {
  gap: 6px;
  display: flex;
  flex-direction: column;
  height: 100%;
  grid-template-rows: auto minmax(0, 1fr);
}
.mc-flytwatch-aircraft-details-buttonbar-header {
  background-color: #fff;
  padding: 12px;
}
.mc-flytwatch-aircraft-details .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.mc-flytwatch-aircraft-details .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 4px 14px;
}
.mc-flytwatch-aircraft-details-table td {
  text-align: center;
}
.mc-flytwatch-aircraft-details-map {
  background-color: rgba(128, 128, 128, 0.2);
  border-radius: 4px;
  flex: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}
.mc-flytwatch-aircraft-details-map .maplibregl-map {
  border-radius: 4px;
}
.mc-flytwatch-aircraft-details .mc-location-icon.mc-location-icon-start,
.mc-flytwatch-aircraft-details .mc-location-icon.mc-location-icon-end {
  margin-right: 6px;
  font-size: 20px;
  transform: translateY(2px);
}
.mc-flytwatch-aircraft-details .mc-location-icon.mc-location-icon-start {
  color: #52c41a;
}
.mc-flytwatch-aircraft-details .mc-location-icon.mc-location-icon-end {
  color: #f5222d;
}
