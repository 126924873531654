/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
div.search-header {
  border-bottom: 1px solid #d9d9d9;
}
div.search-header-content {
  overflow: auto;
}
div.search-header-col {
  padding: 0.5rem;
}
.search-header-input {
  display: block;
  width: 100%;
}
.search-loading-icon {
  margin-top: 0.45rem;
}
div.search-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}
div.search-header-select-count-col {
  display: flex;
}
div.search-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  background: shade(@component-background, 10%);
}
span.search-header-input-group > input {
  display: inline-block !important;
  width: auto !important;
}
div.search-header-options {
  display: block;
  margin-top: 6px;
}
