.mc-maintenance-fullscreen {
    background: #204784;
    text-align: center;
    color: white;
    padding-top: 10rem;
    height: 100vh;
}

.mc-maintenance-fullscreen > h1 {
    color: white;
}