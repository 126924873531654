/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-fb-location {
  padding: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  overflow: auto;
}
.mc-fb-location-content-wrapper {
  width: 50rem;
  max-width: 70rem;
}
.mc-fb-location-content {
  border-radius: 1rem;
  background-color: #fff;
  padding: 1rem;
}
.mc-fb-location .mc-fb-location-title {
  margin-left: 24px !important;
  margin-bottom: 1rem !important;
}
.mc-fb-location .mc-fb-location-controls-table tbody > tr {
  padding: 12px 0;
  position: relative;
}
.mc-fb-location .mc-fb-location-controls-table tbody > tr > th {
  text-align: right;
  padding-right: 12px;
}
.mc-fb-location .mc-fb-location-controls-table tbody > tr > th,
.mc-fb-location .mc-fb-location-controls-table tbody > tr > td {
  vertical-align: top;
}
