

.mc-enhanced-table-group {

  position: relative;

  &-scroll {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: unset;
    overflow: auto;

    & > .mc-enhanced-table,
    & > .mc-enhanced-table > .ant-spin-nested-loading,
    & > .mc-enhanced-table > .ant-spin-nested-loading > .ant-spin-container,
    & > .mc-enhanced-table > .ant-spin-nested-loading > .ant-spin-container > .ant-table
    {
      min-width: fit-content;
      width: 100%;
      position: unset;
    }

  }

}