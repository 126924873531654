
.ant-tabs.ant-tabs-flex {
    display: flex;
    flex-direction: column;
}

.ant-tabs.ant-tabs-flex > .ant-tabs-content {
    flex: 1;
    min-height: 0;
    position: relative;
}

.ant-tabs.ant-tabs-flex > .ant-tabs-content > .ant-tabs-tabpane {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
}

div.ant-tabs-use-padding > .ant-tabs-bar {
    margin: 0;
}


div.ant-tabs-use-padding > .ant-tabs-bar.ant-tabs-card-bar {
    padding: 0 0.4rem;
}

div.ant-tabs-use-padding .ant-tabs-nav-container {
    padding-top: 0.4rem;
    height: 46px !important;
}

div.ant-tabs-use-padding .ant-tabs-extra-content {
    line-height: 45px;
}
