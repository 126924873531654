/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.destination-list-arrow {
  height: 22px;
  white-space: nowrap;
  font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  display: inline-block;
  fill: #fff;
  stroke: #d9d9d9;
  stroke-width: 2px;
  position: relative;
}
.destination-list path {
  fill: #fff;
  stroke: #d9d9d9;
  stroke-width: 2px;
}
.destination-list .destination-list-text {
  position: absolute;
}
.arrowbox {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.arrowbox .arrowbox-body {
  border: 2px solid #d9d9d9;
  border-right-color: #fff;
  border-left-color: #fff;
  background-color: #fff;
  display: inline-block;
  height: 22px;
  max-height: 22px;
  padding: 0.1rem 0.5rem;
  margin-left: -2px;
  margin-right: -1px;
}
.arrowbox .arrowbox-text {
  position: relative;
  bottom: 3px;
}
.arrowbox .arrowbox-body-begin {
  border-left-color: #d9d9d9;
  margin-left: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.arrowbox .arrowbox-edge {
  display: inline-block;
  margin-left: -1.8px;
  height: 22px;
  position: relative;
  top: 2px;
}
.arrowbox .arrowbox-edge .arrowbox-edge-fill {
  color: #fff;
  fill: currentColor;
}
.arrowbox .arrowbox-edge .arrowbox-edge-stroke {
  color: #d9d9d9;
  stroke: currentColor;
  stroke-width: 2px;
  fill: none;
}
