/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
:root {
  color-scheme: light;
}
body {
  margin: 0;
  padding: 0;
  background-color: #f2f4f5;
}
.mc-navbar {
  background: #0050b3 !important;
  color: #ffffff;
  display: flex;
  z-index: 1;
  width: 100%;
  line-height: 3rem;
  height: 3rem;
  padding: 0;
}
.mc-navbar .ant-btn {
  color: #fff;
}
.mc-navbar .ant-divider {
  background: rgba(255, 255, 255, 0.3);
}
.mc-navbar-menu-button .anticon {
  position: relative;
  bottom: 2px;
}
.mc-navbar.ant-layout-header {
  line-height: 3rem;
  height: 3rem;
  padding: 0;
}
.mc-navbar .mc-logo {
  width: 12rem;
  background-image: url('../img/mc-logo-wide-white.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 0.9rem;
  transition: width 0.2s ease-in-out;
}
@media screen and (max-width: 1048px) {
  .mc-navbar .mc-logo {
    width: 10rem;
  }
}
@media screen and (max-width: 560px) {
  .mc-navbar .mc-logo {
    display: none;
  }
  .mc-navbar .mc-stage {
    display: none;
  }
}
.mc-navbar > .mc-menu {
  background: transparent;
}
.mc-navbar .mc-input > .ant-input,
.mc-navbar .mc-select .ant-select-selection {
  background-color: rgba(255, 255, 255, 0.1);
  color: white !important;
  border-width: 0 !important;
  box-shadow: none !important;
}
.mc-navbar .mc-select {
  width: 12rem;
}
@media screen and (max-width: 1048px) {
  .mc-navbar .mc-select {
    width: 8rem;
  }
}
.mc-navbar .mc-input.ant-input-affix-wrapper:hover > .ant-input {
  border-width: 0 !important;
}
.mc-navbar .mc-input .anticon {
  color: #bcbcbc;
}
.mc-navbar .mc-select .ant-select-arrow {
  color: white;
}
.metadata {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.25rem;
  margin: 0.25rem;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.75);
  font-size: 0.65rem;
  color: rgba(0, 0, 0, 0.5);
}
.draggable-table tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}
.draggable-table tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}
.ant-table-row.selected {
  background: #1890ff6b !important;
  box-shadow: inset 0 0 0 1px #ffffff66;
  color: white;
}
.search-filter-dropdown {
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}
.search-filter-dropdown input {
  width: 130px;
  margin-right: 8px;
}
.search-filter-dropdown button {
  margin-right: 8px;
}
.filter-highlight {
  color: #f50;
}
.mc-rdg-no-select .rdg-selected {
  border: none;
}
.react-grid-Row-wrapper.react-grid-Row-wrapper-selected > .react-grid-Row > .react-grid-Cell {
  background-color: #519fff !important;
  color: white;
}
.mc-rdg-row-hover .react-grid-Row-wrapper:not(.react-grid-Row-wrapper-selected) {
  cursor: pointer;
}
.mc-rdg-row-hover .react-grid-Row-wrapper:hover:not(.react-grid-Row-wrapper-selected) > .react-grid-Row > .react-grid-Cell {
  background-color: rgba(81, 159, 255, 0.3) !important;
}
.ant-layout-header.ant-layout-header-small {
  padding: 0 1rem;
  line-height: 48px;
  height: 48px;
}
.ant-layout-header.ant-layout-header-bordered {
  border-bottom: 1px solid #d9d9d9;
}
.ant-layout-sider.ant-layout-sider-bordered-right {
  border-right: 1px solid #d9d9d9;
}
.ant-layout-sider.ant-layout-sider-bordered-left {
  border-right: 1px solid #d9d9d9;
}
.ant-spin-nested-loading.ant-spin-fill-height {
  height: 100%;
}
.ant-spin-nested-loading.ant-spin-fill-height > .ant-spin-container {
  height: 100%;
}
.ant-table-wrapper.borderless .ant-table,
.ant-card-with-table .ant-table {
  border: none;
}
.ant-table-wrapper.marginless .ant-table-body,
.ant-card-with-table .ant-table-body {
  margin: 0 !important;
}
.ant-card.small-tabs .ant-tabs-tab {
  padding: 10px 16px !important;
}
.ant-card-with-table .ant-card-head {
  background: #fff;
}
.ant-card-with-table .ant-table thead {
  background: #fff;
}
.ant-card-with-table .ant-card-body {
  padding: 1px 0 0 0 !important;
}
.ant-card.ant-card-shadow {
  box-shadow: rgba(0, 0, 0, 0.17) 0px 0px 2px;
}
.bp3-table-cursor-pointer .bp3-table-selection-enabled .bp3-table-cell {
  cursor: pointer !important;
}
button.mc-btn-sharp {
  border-radius: 0;
}
button.mc-btn-transparent.mc-btn-transparent-with-border {
  border-color: #d9d9d9;
}
button.mc-btn-transparent {
  background: transparent;
  border-color: transparent;
}
button.mc-btn-transparent:hover {
  background: rgba(0, 0, 0, 0.1);
  border-color: transparent;
}
button.mc-btn-transparent:active {
  background: rgba(0, 0, 0, 0.15);
  border-color: #1890ff;
}
button.mc-btn-transparent:focus {
  background: rgba(0, 0, 0, 0.05);
  border-color: #d9d9d9;
}
button.mc-btn-transparent.mc-btn-hover-outline:hover {
  border-color: #1890ff;
}
button.mc-btn-green {
  background-color: #03af2c;
  border-color: #03af2c;
  color: white !important;
}
button.mc-btn-green:hover,
button.mc-btn-green:focus {
  background-color: #2bb94c;
  border-color: #2bb94c;
}
button.mc-btn-green:active {
  background-color: #048522;
  border-color: #048522;
}
.ant-spin-fill-height,
.ant-spin-fill-height > .ant-spin-nested-loading,
.ant-spin-fill-height > .ant-spin-nested-loading > .ant-spin-container {
  height: 100%;
}
.reflex-container > .reflex-splitter {
  box-sizing: content-box;
  background-color: #f5f8fa;
}
.reflex-container.vertical > .reflex-splitter {
  border-left: 1px solid #d9d9d9 !important;
  border-right: 1px solid #d9d9d9 !important;
  width: 4px !important;
}
.reflex-container.horizontal > .reflex-splitter {
  border-top: 1px solid #d9d9d9 !important;
  border-bottom: 1px solid #d9d9d9 !important;
  height: 4px !important;
}
.reflex-container > .reflex-splitter.active,
.reflex-container > .reflex-splitter:hover {
  background-color: fade(tint(@primary-color, 30%), 40%);
  transition: none !important;
}
.rv-discrete-color-legend-item__title {
  color: rgba(0, 0, 0, 0.65);
}
.app-loading-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-form-item.ant-form-item-no-margin {
  margin-bottom: 0;
}
.ant-form-item.ant-form-item-less-margin {
  margin-bottom: 0.5rem;
}
.mc-page-header-extra-flex .ant-page-header-heading-extra {
  display: flex;
  align-items: center;
}
.mc-page-header-bottom-border {
  border-bottom: 1px solid #d9d9d9;
}
.mc-page-header-compact {
  padding: 1px;
}
.mc-tabs-pill > .ant-tabs-bar .ant-tabs-ink-bar {
  bottom: 7px;
  height: calc(100% - 14px);
  border-radius: 4px;
}
.mc-tabs-pill > .ant-tabs-bar .ant-tabs-tab-active {
  z-index: 2;
  color: #ffffff;
}
.mc-tabs-pill > .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab-active:hover {
  color: rgba(255, 255, 255, 0.75);
}
.mc-tabs-pill > .ant-tabs-bar .ant-tabs-ink-bar {
  background: linear-gradient(0deg, #1373cc, #46a6ff);
}
.mc-tabs-pill > .ant-tabs-bar {
  border: none;
}
.mc-tabs-pill > .ant-tabs-bar .ant-tabs-nav-container {
  margin-bottom: 0;
}
.mc-tabs-pill > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.ant-dropdown-menu {
  border: 1px solid #d9d9d9;
}
.mc-rdg-fill-height > .react-grid-Container,
.mc-rdg-fill-height > .react-grid-Container > .react-grid-Main,
.mc-rdg-fill-height > .react-grid-Container > .react-grid-Main > .react-grid-Grid {
  height: 100%;
}
.mc-rdg-fill-height > .react-grid-Container > .react-grid-Main > .react-grid-Grid {
  min-height: 0;
}
@media screen and (max-width: 1340px) {
  .mc-show-larger-than-large-screen {
    display: none !important;
  }
}
@media screen and (min-width: 1340px) {
  .mc-show-smaller-than-large-screen {
    display: none !important;
  }
}
@media screen and (max-width: 1048px) {
  .mc-show-larger-than-medium-screen,
  .ant-btn > .mc-show-larger-than-medium-screen {
    display: none !important;
  }
  .mc-main-app-layout.mc-main-app-layout-sider-open > .mc-main-app-layout-click-receiver {
    display: block !important;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
  .mc-main-app-layout > .mc-main-app-layout-content {
    filter: brightness(1);
    transition: filter 0.2s;
  }
  .mc-main-app-layout.mc-main-app-layout-sider-open > .mc-main-app-layout-content {
    filter: brightness(0.5);
  }
  .mc-main-app-layout > .ant-layout-sider {
    box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    overflow: hidden;
  }
  .mc-main-app-layout > .ant-layout-sider.ant-layout-sider-collapsed {
    box-shadow: none;
    width: 0 !important;
    max-width: 0 !important;
    min-width: 0 !important;
  }
}
@media screen and (min-width: 1048px) {
  .mc-show-smaller-than-medium-screen,
  .ant-btn > .mc-show-smaller-than-medium-screen {
    display: none !important;
  }
}
@media screen and (max-width: 850px) {
  .mc-show-larger-than-small-screen,
  .ant-btn > .mc-show-larger-than-small-screen {
    display: none !important;
  }
}
@media screen and (min-width: 850px) {
  .mc-show-smaller-than-small-screen,
  .ant-btn > .mc-show-larger-than-small-screen {
    display: none !important;
  }
}
@media screen and (max-width: 560px) {
  .mc-show-only-larger-than-mobile-screen,
  .ant-btn > .mc-show-only-larger-than-mobile-screen {
    display: none !important;
  }
}
.mc-link-btn,
.ant-btn.mc-link-btn {
  padding: 0;
  margin: 0;
  display: inline;
  color: #1890ff;
  border: none;
  height: initial;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
.ant-btn.mc-link-btn:after {
  box-shadow: none !important;
}
.mc-link-btn:active,
.ant-btn.mc-link-btn:active {
  color: #096dd9;
}
.mc-link-btn:hover,
.ant-btn.mc-link-btn:hover {
  color: #40a9ff;
}
.mc-popout-wrapper {
  background-color: #fff;
  padding: 12px;
  height: 100vh;
}
.mc-main-app-layout {
  position: relative;
}
.mc-main-app-layout.mc-main-app-layout-sider-open::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  transition: background-color 1000ms;
  background-color: transparent;
  z-index: 4;
  display: none;
}
.mc-main-app-layout-click-receiver {
  display: none;
}
.ant-time-picker-panel-input {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.65);
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: linear-gradient(90deg, #e6e6e6 25%, #cccccc 37%, #e6e6e6 63%);
  background-size: 400% 100%;
}
.ant-select.mc-fix-select-selection-width .ant-select-selection-selected-value {
  width: 100%;
}
.mc-form-item-label-no-shrink > .ant-form-item-label {
  flex-shrink: 0;
}
:not(.ant-upload-list-item-error) .ant-upload-list-item-card-actions .anticon {
  color: rgba(0, 0, 0, 0.45) !important;
}
:not(.ant-upload-list-item-error) .ant-upload-list-item-card-actions .anticon:hover {
  color: #1890ff !important;
}
.mc-form-item-no-margin.ant-form-item {
  margin-bottom: 0;
}
.mc-cursor-pointer {
  cursor: pointer;
}
.mc-border {
  border: 1px solid #d9d9d9;
}
.mc-border-bottom {
  border-bottom: 1px solid #d9d9d9;
}
.mc-border-left {
  border-left: 1px solid #d9d9d9;
}
.mc-text-highlight,
.ant-btn.mc-text-highlight {
  color: #d48806;
}
.mc-text-highlight:hover,
.ant-btn.mc-text-highlight:hover {
  color: #faad14;
}
