/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-editable-cell .mc-editable-cell-text-after {
  color: #52c41a;
}
.mc-editable-cell .mc-editable-cell-text-before {
  color: red;
}
