/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.compliance-rule-select {
  display: flex;
  align-items: baseline;
}
.compliance-rule-select .compliance-rule-select-change-text {
  margin-left: auto;
  color: #1890ff;
}
