/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-global-filter-wrapper {
  background: #fff;
  padding: 24px;
  margin: -24px -24px 24px -24px;
  border-bottom: 1px solid #d9d9d9;
}
.mc-global-filter-wrapper.mc-global-filter-wrapper-hidden {
  padding: 12px 24px;
}
