/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-calendar .ant-fullcalendar-date {
  border: 2px solid #d9d9d9;
  margin: -2px;
  padding: 4px 10px;
  height: calc(100% + 4px);
}
table.mc-calendar,
.mc-calendar table,
.mc-calendar .ant-fullcalendar-calendar-body,
.mc-calendar .ant-fullcalendar,
.mc-calendar {
  height: 100%;
  max-width: 200rem;
}
.mc-calendar .ant-fullcalendar-column-header-inner {
  text-transform: uppercase;
  font-weight: bold;
  opacity: 0.5;
}
.mc-calendar .ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
  color: #1890ff;
}
.mc-calendar .ant-fullcalendar-date.ant-fullcalendar-date-disabled > .ant-fullcalendar-value,
.mc-calendar .ant-fullcalendar-date.ant-fullcalendar-date-disabled > .ant-fullcalendar-content {
  color: rgba(0, 0, 0, 0.45);
}
.mc-calendar .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month,
.mc-calendar .ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background: #e6f7ff;
}
.mc-calendar .ant-fullcalendar-fullscreen .ant-fullcalendar-month:active,
.mc-calendar .ant-fullcalendar-fullscreen .ant-fullcalendar-date:active {
  background: #bae7ff;
}
