/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.admin-submenu,
.admin-submenu.ant-menu-submenu-active,
.admin-submenu.ant-menu-submenu-open,
.admin-submenu.ant-menu-submenu-selected {
  background-color: #290000;
}
.admin-submenu .ant-menu-inline.ant-menu-sub {
  background: #170000;
}
.admin-submenu.ant-menu-submenu .ant-menu .ant-menu-item-selected,
.admin-submenu.ant-menu-submenu .ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #c70000;
}
