
.color-mixin(@bgColor, @textColor){
  color: @textColor;
  background-color: @bgColor;
}

.color-border-mixin(@bgColor){
  border-top: 1px solid tint(@bgColor, 50%);
  border-right: 1px solid tint(@bgColor, 35%);
  border-left: 1px solid tint(@bgColor, 25%);
  border-bottom: 1px solid shade(@bgColor, 50%);
}

.pill-mixin(@bgColor, @textColor) {
  .color-mixin(@bgColor, @textColor);
  .color-border-mixin(@bgColor);
}

.tr-color-mixin(@bgColor, @textColor){
  & > th,
  & > td {
    .color-mixin(@bgColor, @textColor);
  }
}