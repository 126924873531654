/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.flight-preview-property {
  line-height: 14px;
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
}
.flight-preview-label {
  width: 5rem;
  margin-right: 1rem;
  display: inline-block;
  color: rgba(0, 0, 0, 0.65);
}
.flight-preview-section {
  margin-bottom: 1.5rem;
}
.flight-preview-section h3 {
  text-transform: uppercase;
}
.flight-preview-state-OVERNIGHT {
  color: #BF97E1;
}
