/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-enhanced-table tfoot > tr > td {
  border-top: 1px solid #d9d9d9;
}
.mc-enhanced-table .mc-enhanced-table-group-header > tr > th {
  border-top: 1px solid #d9d9d9;
}
.mc-enhanced-table .mc-enhanced-table-group-header > tr:first-child > th:first-child {
  border-top-left-radius: 0;
}
.mc-enhanced-table .mc-enhanced-table-group-header > tr:first-child > th:last-child {
  border-top-right-radius: 0;
}
.mc-enhanced-table.mc-enhanced-table-auto-scroll-fix .ant-table-scroll > .ant-table-body {
  overflow-x: auto !important;
}
.mc-enhanced-table .ant-table-thead > tr > th,
.mc-enhanced-table .ant-table-tfoot > tr > th,
.mc-enhanced-table .mc-enhanced-table-footer > tr > td {
  background-color: #fafafa !important;
  border-bottom-color: #0050b3 !important;
  border-bottom-width: 2px !important;
  color: #0050b3 !important;
}
.mc-enhanced-table .ant-table-tfoot > tr > th {
  border-bottom-width: 0;
  border-top-width: 1px;
  border-top-color: #0050b3 !important;
}
.mc-enhanced-table:not(.mc-enhanced-table-bordered) .ant-table,
.mc-enhanced-table:not(.mc-enhanced-table-bordered) .ant-table.ant-table-small {
  border: none !important;
}
.mc-enhanced-table.mc-enhanced-table-square-corners .ant-table,
.mc-enhanced-table.mc-enhanced-table-square-corners .ant-table.ant-table-small {
  border-radius: 0 !important;
}
.mc-enhanced-table.mc-enhanced-table-square-corners .ant-table-thead tr:first-child th:first-child,
.mc-enhanced-table.mc-enhanced-table-square-corners .mc-enhanced-table-header tr:first-child,
.mc-enhanced-table.mc-enhanced-table-square-corners .mc-enhanced-table-header tr:first-child th:first-child,
.mc-enhanced-table.mc-enhanced-table-square-corners .ant-table-thead tr:first-child {
  border-top-left-radius: 0px !important;
}
.mc-enhanced-table.mc-enhanced-table-square-corners .ant-table-thead tr:first-child th:last-child,
.mc-enhanced-table.mc-enhanced-table-square-corners .mc-enhanced-table-header tr:first-child,
.mc-enhanced-table.mc-enhanced-table-square-corners .mc-enhanced-table-header tr:first-child th:first-child,
.mc-enhanced-table.mc-enhanced-table-square-corners .ant-table-thead tr:first-child {
  border-top-right-radius: 0px !important;
}
.mc-enhanced-table.mc-enhanced-table-square-corners .mc-enhanced-table-footer tr:first-child,
.mc-enhanced-table.mc-enhanced-table-square-corners .mc-enhanced-table-footer tr:first-child td:first-child {
  border-bottom-left-radius: 0 !important;
}
.mc-enhanced-table.mc-enhanced-table-square-corners .mc-enhanced-table-footer tr:first-child,
.mc-enhanced-table.mc-enhanced-table-square-corners .mc-enhanced-table-footer tr:first-child td:last-child {
  border-bottom-right-radius: 0 !important;
}
.mc-enhanced-table tfoot > tr > td,
.mc-enhanced-table .mc-enhanced-table-footer > tr > td,
.mc-enhanced-table .mc-enhanced-table-totals {
  color: #0050b3;
}
.mc-enhanced-table .mc-enhanced-table-totals > tr > td {
  border-bottom: 1px solid #d9d9d9 !important;
  background-color: #fafafa;
  border-bottom-color: #0050b3 !important;
  border-bottom-width: 2px !important;
}
.mc-enhanced-table .mc-enhanced-table-footer.mc-enhanced-table-grouped-footer:not(:last-child) > tr > td {
  border-bottom: 1px solid #d9d9d9 !important;
}
.mc-enhanced-table .mc-enhanced-table-footer.mc-enhanced-table-grouped-footer:not(:last-child) > tr > td:first-child {
  border-bottom-left-radius: 0 !important;
}
.mc-enhanced-table .mc-enhanced-table-footer.mc-enhanced-table-grouped-footer:not(:last-child) > tr > td:last-child {
  border-bottom-right-radius: 0 !important;
}
.mc-enhanced-table .mc-enhanced-table-footer > tr:first-child > td:first-child {
  border-bottom-left-radius: 4px;
}
.mc-enhanced-table .mc-enhanced-table-footer > tr:first-child > td:last-child {
  border-bottom-right-radius: 4px;
}
.mc-enhanced-table-with-group-spacing > tr.ant-table-row:last-child > td {
  border-bottom: 1px solid #d9d9d9 !important;
}
.mc-enhanced-table.mc-enhanced-table-sticky-header thead > tr > th {
  position: sticky;
  top: 0px;
}
.mc-enhanced-table .mc-enhanced-table-footer-sticky > tr {
  position: sticky;
  bottom: 0;
}
.mc-enhanced-table.mc-enhanced-table-sticky-header-12 thead > tr > th {
  top: 12px;
}
.mc-enhanced-table:not(.mc-enchanced-table-flexmode) .ant-table,
.mc-enhanced-table:not(.mc-enchanced-table-flexmode) .ant-table-content,
.mc-enhanced-table:not(.mc-enchanced-table-flexmode) .ant-table-scroll,
.mc-enhanced-table:not(.mc-enchanced-table-flexmode) .ant-table-body {
  min-width: fit-content;
}
.mc-enhanced-table.mc-enchanced-table-flexmode {
  min-height: 0;
  position: relative;
}
.mc-enhanced-table.mc-enchanced-table-flexmode > div,
.mc-enhanced-table.mc-enchanced-table-flexmode > div > div,
.mc-enhanced-table.mc-enchanced-table-flexmode > div > div > div,
.mc-enhanced-table.mc-enchanced-table-flexmode > div > div > div > div,
.mc-enhanced-table.mc-enchanced-table-flexmode > div > div > div > div > .ant-table-body {
  flex: 1 !important;
  min-height: 0 !important;
}
.mc-enhanced-table.mc-enchanced-table-flexmode .ant-table-body {
  overflow: auto;
}
.mc-enhanced-table.mc-enchanced-table-flexmode,
.mc-enhanced-table.mc-enchanced-table-flexmode > div,
.mc-enhanced-table.mc-enchanced-table-flexmode > div > div,
.mc-enhanced-table.mc-enchanced-table-flexmode > div > div > div,
.mc-enhanced-table.mc-enchanced-table-flexmode > div > div > div > div,
.mc-enhanced-table.mc-enchanced-table-flexmode > div > div > div > div > div:not(.ant-table-body) {
  display: flex;
  flex-direction: column;
}
.mc-enhanced-table.mc-enchanced-table-flexmode > div.ant-spin-nested-loading > div:not(.ant-spin-container) {
  max-height: 0;
}
.mc-enhanced-table.mc-enchanced-table-flexmode > div.ant-spin-nested-loading > div:not(.ant-spin-container) > .ant-spin {
  max-height: unset;
}
.mc-enhanced-table.light-theme-only .ant-table {
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  border-bottom: none;
  border-right: none;
  border-left: none;
}
.mc-enhanced-table:not(.light-theme-only) .ant-table {
  border-right: 1px solid #d9d9d9;
}
.mc-enhanced-table .ant-table.ant-table-bordered .ant-table-content {
  border-right: none;
}
.mc-enhanced-table.light-theme-only .ant-table-row:nth-child(even) {
  background: hsl(212, 59%, 86%) !important;
}
.mc-enhanced-table.light-theme-only .ant-table-row:nth-child(even):hover > td {
  background: hsl(212, 64%, 83%) !important;
}
.mc-enhanced-table.light-theme-only .ant-table-row:nth-child(even) > td {
  border-color: rgba(0, 0, 0, 0.15) !important;
}
