/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.paxcheckin-sider .ant-layout-header {
  background-color: #fff;
}
.paxcheckin-sider .ant-layout-content {
  background-color: #fff;
  padding: 1rem;
  overflow: auto;
}
.paxcheckin-content .ant-layout-header {
  background-color: #fff;
  overflow: hidden;
}
