/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.live-flight-editor-header {
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
}
.live-flight-editor-header .ant-page-header-back-button {
  color: rgba(0, 0, 0, 0.65);
}
.live-flight-editor-legs-tabs > .ant-tabs-bar {
  margin-left: 6px;
  margin-right: 6px;
}
.live-flight-editor-legs-tabs.mc-tabs-pill > .ant-tabs-bar {
  padding: 0px 6px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}
.live-flight-editor-legs-tabs .mc-enhanced-table thead > tr > th {
  background-color: #fff !important;
}
.live-flight-editor-legs-tabs .ant-table {
  border: none !important;
}
.live-flight-editor-legs-tabs .ant-card-with-table .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 14px 0 !important;
}
