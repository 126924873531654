/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.reporting-detail-header {
  background: #fff;
  padding: 0 1rem;
  border: 1px solid #e8e8e8;
  border-width: 0 0 1px 1px;
}
