/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-editable-text-input.ant-input {
  width: auto;
  height: initial;
}
.mc-editable-text-input.ant-input,
.mc-editable-text-editing {
  padding: 1px 6px;
  line-height: inherit;
  margin: 0 -6px;
}
.mc-editable-text-editing {
  display: inline-block;
  cursor: text;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  border-color: transparent;
  transition: border-color 100ms;
  font-weight: inherit;
  font-size: inherit;
}
.mc-editable-text-editing.mc-editable-text-editing-disabled {
  border-color: transparent;
}
.mc-editable-text-editing:hover {
  border-color: #d9d9d9;
}
