/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.passenger-editor-personal-info .ant-card-head {
  color: #fff;
  font-size: 20px;
  background-color: #108ee9;
}
.passenger-editor-personal-info .pepi-value {
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
}
