/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-collapse-flex {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.ant-collapse-flex > .ant-collapse-item.ant-collapse-item-active {
  flex: 1;
}
.ant-collapse-flex > .ant-collapse-item {
  display: flex;
  flex-direction: column;
}
.ant-collapse-flex > .ant-collapse-item > .ant-collapse-content-active {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.ant-collapse-flex .ant-collapse-content-box {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.ant-collapse-item.ant-collapse-content-less-padding .ant-collapse-content-box {
  padding: 6px;
}
.mc-collapse-rounded > .ant-collapse-item > .ant-collapse-header {
  background-color: #fafafa;
}
.mc-collapse-rounded.ant-collapse {
  border: none;
  background-color: transparent;
}
.mc-collapse-rounded > .ant-collapse-item {
  border: 1px solid #d9d9d9;
  border-radius: 4px !important;
  overflow: hidden;
}
.mc-collapse-rounded > .ant-collapse-item:not(:last-child) {
  margin-bottom: 12px;
}
.mc-collapse-rounded-has-table > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.mc-collapse-rounded-has-table > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box > .ant-table {
  border: none;
}
.mc-collapse-rounded-has-table > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box > .ant-table .ant-table-body {
  margin: 0;
}
