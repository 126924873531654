/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mc-live-header {
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  align-items: stretch;
  min-height: 56px;
}
.mc-live-header-title-wrapper {
  display: flex;
  flex-direction: column;
  padding: 12px 8px 12px 12px;
  margin-left: 8px;
}
.mc-live-header-title-wrapper-centered {
  justify-content: center;
}
.mc-live-header-title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 20px;
  font-weight: 600;
  line-height: 19px;
}
.mc-live-header-subtitle {
  color: rgba(0, 0, 0, 0.65);
  margin-top: 0.25rem;
}
.mc-live-header-tabs {
  margin-left: 2rem;
}
.mc-live-header-tabs .ant-tabs-tab {
  line-height: 33px;
}
.mc-live-header-tabs .ant-tabs-bar {
  margin: 0;
  border-color: #fff;
}
.mc-live-header-tabs .ant-tabs-tab {
  margin: 0;
}
.mc-live-header-contain-wrapper {
  flex: 1;
  display: flex;
  align-items: stretch;
}
.mc-live-header-contain-left {
  justify-content: flex-start;
  flex: 1;
}
.mc-live-header-contain-left > .mc-live-header-contain-padded {
  padding: 12px 8px 12px 12px;
}
.mc-live-header-contain-middle {
  justify-content: center;
  flex-shrink: 1;
}
.mc-live-header-contain-middle > .mc-live-header-contain-padded {
  padding: 12px 8px 12px 8px;
}
.mc-live-header-contain-right {
  justify-content: flex-end;
  flex: 1;
}
.mc-live-header-contain-right > .mc-live-header-contain-padded {
  padding: 12px 12px 12px 8px;
}
.mc-live-header-contain-padded {
  display: flex;
  align-items: stretch;
}
.mc-live-header-contain-item {
  display: flex;
  align-items: center;
}
.mc-live-header-contain-item:not(:last-of-type) > * {
  margin-right: 12px;
}
.mc-live-header-contain-item.no-content {
  display: none;
}
.mc-live-header-contain-left,
.mc-live-header-contain-middle,
.mc-live-header-contain-right {
  display: flex;
}
.mc-live-header-contain-left.no-content > .mc-live-header-contain-padded,
.mc-live-header-contain-middle.no-content > .mc-live-header-contain-padded,
.mc-live-header-contain-right.no-content > .mc-live-header-contain-padded {
  padding-left: 0;
  padding-right: 0;
}
.mc-live-header .ant-divider.ant-divider-vertical {
  background: linear-gradient(0deg, #cecece, #d9d9d9);
}
.mc-live-header.mc-live-header-compact {
  padding: 8px 12px;
}
@media screen and (max-width: 1048px) {
  .mc-live-header-tabs {
    margin-left: 6px;
    margin-right: auto;
  }
  .mc-live-header-title-wrapper-hide-med-screen {
    display: none;
  }
}
