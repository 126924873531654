
.mc-isnChecker {
    padding: 0.5rem 0.5rem;
    text-align: center;
    text-transform: uppercase;
    border-width: 1px;
    border-color: rgb(160,160,160);
    border-style: solid;
    border-radius: 0.20rem;
}

.mc-isnChecker-red {
    color: white;
    background-color: red;
    border-color: rgb(160, 0, 0);
}