
.ant-drawer-flex .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
}

.ant-drawer-flex .ant-drawer-wrapper-body > .ant-drawer-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.ant-drawer-body-no-padding .ant-drawer-body,
.ant-drawer-body.ant-drawer-body-no-padding {
    padding: 0;
}