/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.scheduling-flight-edit-legs-col-left {
  border-right: 1px solid #d9d9d9;
  min-height: 30rem;
}
.scheduling-flight-edit-legs-tabs > .ant-tabs-bar {
  margin-left: 12px;
  position: absolute;
  width: calc(100% - 12px);
  z-index: 1;
}
.scheduling-flight-edit-legs-tabs > .ant-tabs-content {
  margin-top: 39px;
}
